<template>
  <div id="multi-select-drop-down">
    <!-- :selectedItems='arrayList' -->
    <mds-form size="small">
    <mds-combo-box
      :key="multipleSelect"
      size="small"
      :multipleItemLimit="2"
      hiddenLabel
      label="Multi Select" 
      @input="
        change($event);
      "
      multiple
      :data-set="dropDownList"
      v-model="listSelected"
      :disabled="isdisabled || isDisabledOnNa"
      :error="isError"
      :error-text="[errorMessage]"
    ></mds-combo-box>
    </mds-form>
    <div class="multi-select-add-new-modal">
      <mds-modal
        action-required
        :title="'Add ' + datapointName"
        :width="'600px'"
        v-model="toggleAddNewOption"
      >
        <div style="width:70%;margin-bottom: 16px;">
          <mds-input
            :error="validationError"
            :error-text="validationMessages"
            v-model="addNewValue"
            label="Name"
          ></mds-input>
        </div>
        <template v-slot:mds-modal-actions>
          <mds-button-container right-aligned>
            <button-component
              :onClick="cancelClick"
              buttonName="Cancel"
              buttonVariation="secondary"
            ></button-component>
            <button-component
              :onClick="addClick"
              buttonName="Add"
              buttonVariation="primary"
            ></button-component>
          </mds-button-container>
        </template>
      </mds-modal>
    </div>
     <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :dismissDelay="5000"
      @close="showNotification = false"
    ></notification-component>
  </div>
</template>
<script>
//
import {
  getSelectForMultipleEnum,
  getSelectForMultipleReferenceTypeId,
} from "../../services/add_investment_service";
import MdsModal from "@mds/modal";
import MdsInput from "@mds/input";
import { MdsButtonContainer } from "@mds/button";
import ButtonComponent from "./ButtonComponent.vue";
import { insertNewEntity } from "../../services/add_investment_service";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import MdsComboBox from "@mds/combo-box";
import { mapGetters} from "vuex";
import MdsForm from '@mds/form'
export default {
  components: {
    MdsComboBox,
    MdsModal,
    MdsInput,
    MdsButtonContainer,
    ButtonComponent,
    NotificationComponent,
    MdsForm
  },
  props: [
    "itemId",
    "itemArray",
    "oldItemArray",
    "type",
    "isdisabled",
    "isValidationError",
    "errorMessage",
    "isDisabledOnNa",
    "datapointName",
  ],
  data() {
    return {
      onLoadList:[],
      notificationMessage: "",
      notificationType: "error",
      showNotification: false,
      notificationKey: "",
      validationError: false,
      validationMessages: [],
      addNewValue: "",
      toggleAddNewOption: false,
      listSelected: [],
      enumId: 141,
      arrayList: [],
      dropDownList: [],
      isError: this.isValidationError,
      multipleSelect: 0,
      addEntityPayload: {
        entitytypeid: 0,
        parententitytype: null,
        entitytype: null,
        parententities: null,
        childentities: null,
        childtypes: null,
        entityname: "",
        parententityid: null,
        parententitytypeid: 0,
        parententityname: null,
        entityid: 0,
        iscontadd: "False",
      },
    };
  },
  watch: {
    itemArray: function() {
      if (this.itemArray != null && this.itemArray.constructor === Array) {
        this.arrayList = this.itemArray;
      } else {
        this.arrayList.push(this.itemArray);
      }
      if (this.itemArray != null) {
        this.listSelected = this.arrayList;
        this.onLoadList=this.arrayList;
      } else {
        this.listSelected = [];
        this.onLoadList=[]
      }
    },
  },
   computed: {
...mapGetters("dropDowns", ["getEnumDropDowns","getReferenceDropDowns"]),
    
  },
  async created() {
    // this.listSelected=this.itemArray
    if (this.itemArray != null && this.itemArray.constructor === Array) {
      this.arrayList = this.itemArray;
    } else {
      this.arrayList.push(this.itemArray); 
    }
    if (this.type == "selectForEnum") {
        var responseData = await this.getSelectDropDownForMultipleEnumId();
        var value=null
        for(let i=0;i<responseData.length;i++){
            var dropDownObject={
              disabled: responseData[i].disabled,
              enumid: responseData[i].enumid,
              selected: responseData[i].selected,
              text: responseData[i].text,
              value: responseData[i].value,
              sortorder:responseData[i].sortorder
            }
            for(let j=0;j<this.arrayList.length;j++){
              if(responseData[i].value == this.arrayList[j]){
                dropDownObject.selected=true
                 if(value == null){
                  value=responseData[i].text
                }else{
                  value=value+";"+responseData[i].text
                }
              //  this.$emit("text",responseData[i].text)
              }else{
                if(j == this.arrayList.length-1){
                 dropDownObject.selected=false
                }
              }

            }
            
            this.dropDownList.push(dropDownObject)
          }
          this.dropDownList.sort((a, b) => 
            a.sortorder - b.sortorder
          )
          this.$emit("text",value)
      // this.dropDownList = await this.getSelectDropDownForMultipleEnumId();
      if (this.itemArray != null) {
        this.listSelected = this.arrayList;
        this.onLoadList=this.arrayList
      }
    } else if (this.type == "selectForReference") {
     var responseReferenceData = await this.getSelectDropDownForReferenceId();
     
     var textValue = null
        for(let i=0;i<responseReferenceData.length;i++){
            var refDropDownObject={
              disabled: responseReferenceData[i].disabled,
              referenceid: responseReferenceData[i].referenceid,
              selected: responseReferenceData[i].selected,
              text: responseReferenceData[i].text,
              value: responseReferenceData[i].value.toString()
            }
            for(let j=0;j<this.arrayList.length;j++){
              if(responseReferenceData[i].value == this.arrayList[j]){
                refDropDownObject.selected=true
                 if(textValue == null){
                  textValue= responseReferenceData[i].text
                }else{
                  textValue=textValue+";"+ responseReferenceData[i].text
                }
              }else{
                if(j == this.arrayList.length-1){
                 refDropDownObject.selected=false
                }
              }

            }
            this.dropDownList.push(refDropDownObject)
          }
           this.$emit("text",textValue)
      // if (
      //   this.type == "selectForReference" &&
      //   this.itemId != 1 &&
      //   this.itemId != 2 &&
      //   this.itemId != 3 &&
      //   this.itemId != 4
      // ) {
      //   var addnewObject = {
      //     disabled: false,
      //     group: null,
      //     selected: false,
      //     text: "+ Add new",
      //     value: "addNew",
      //   };
      //   this.dropDownList.splice(0, 0, addnewObject);
      // }

      if (this.itemArray != null) {
        this.listSelected = this.arrayList;
        this.onLoadList=this.arrayList
      }
    }
    this.multipleSelect++
  },
  methods: {
    setDatapointText(){
      var value=null
      for(let i=0;i<this.dropDownList.length;i++){
        for(let j=0;j< this.listSelected.length;j++){
          if(this.dropDownList[i].value == this.listSelected[j]){
                 if(value == null){
                  value=this.dropDownList[i].text
                }else{
                  value=value+";"+this.dropDownList[i].text
                }
            }
        }
      }
      return value
    },

    async getSelectDropDownForMultipleEnumId() {
       if(this.getEnumDropDowns.length != 0){
             for(let i=0;i<this.getEnumDropDowns.length;i++){
               if(this.getEnumDropDowns[i].enumid == this.itemId){
                 return this.getEnumDropDowns[i].values
               }else{
                 if(i == this.getEnumDropDowns.length-1){
                  var response = await getSelectForMultipleEnum(this.itemId,this.arrayList);
                     return response.data
                 }
               }
             }
           }else{
             var responseData =  await getSelectForMultipleEnum(this.itemId,this.arrayList)
                    return responseData.data
           }
    },
     async  getSelectDropDownForReferenceId(){
           if(this.getReferenceDropDowns.length != 0){
             for(let i=0;i<this.getReferenceDropDowns.length;i++){
               if(this.getReferenceDropDowns[i].refrenceid == this.itemId){
                 return this.getReferenceDropDowns[i].values
               }else{
                 if(i == this.getReferenceDropDowns.length-1){
                   return this.getSelectDropDownForMultipleReferenceTypeId()
                 }
               }
             }

           }else{
             return this.getSelectDropDownForMultipleReferenceTypeId()
           }
    },
    async getSelectDropDownForMultipleReferenceTypeId() {
      if (this.itemArray == null) {
        this.arrayList = [0];
      }
      var response = await getSelectForMultipleReferenceTypeId(
        this.itemId,
        this.arrayList
      );
      return response.data;
    },
    async getSelectDropDownForMultipleReferenceTypeIdAfterAddNew(list) {
      var response = await getSelectForMultipleReferenceTypeId(
        this.itemId,
        list
      );
      return response.data;
    },
    async change(e) {
      var checkAddNew = e.filter((item) => item == "addNew");
      if (checkAddNew.length > 0) {
        await this.addNewOption();
      } else {
        if(JSON.stringify(this.onLoadList)!=JSON.stringify(this.listSelected)){
        this.isError=false
        var datapointText = await this.setDatapointText()
        this.$emit("text", datapointText)
        this.$emit("input", this.listSelected);
        this.$emit("valueChange")
        this.onLoadList=this.listSelected
        }
      }
    },
    addNewOption() {
      for (var i = 0; i < this.listSelected.length; i++) {
        if (this.listSelected[i] == "addNew") {
          this.listSelected.splice(i, 1);
        }
      }
      this.toggleAddNewOption = true;
    },
    cancelClick() {
      this.toggleAddNewOption = false;
      this.multipleSelect += 1;
    },
    async addClick() {
      if (this.addNewValue.trim().length == 0) {
        this.validationError = true;
        this.validationMessages = ["The EntityName field is required."];
      } else {
        this.addEntityPayload.entitytypeid = this.itemId;
        this.addEntityPayload.entityname = this.addNewValue;
        this.toggleAddNewOption = false;
        this.$emit("displayLoader", true);
        var response = await insertNewEntity(this.addEntityPayload);
        if (!response.isError) {
          this.$emit("displayLoader", false);
          if(response.data.message){
            if(response.data.message.trim().length > 0){
              this.showNotificationBar(response.data.message, "error");
              this.addNewValue = "";
              this.multipleSelect += 1;
            }
          }else{
              var stringEntityId = response.data.result.entityid.toString();
              var list = [];
              //     list=this.arrayList
              if (this.itemArray == null) {
                list = [stringEntityId];
              } else {
                for (var i = 0; i < this.arrayList.length; i++) {
                  list.push(this.arrayList[i]);
                }
                list.push(stringEntityId);
              }

              this.dropDownList = await this.getSelectDropDownForMultipleReferenceTypeIdAfterAddNew(
                list
              );
              // if (
              //   this.type == "selectForReference" &&
              //   this.itemId != 1 &&
              //   this.itemId != 2 &&
              //   this.itemId != 3 &&
              //   this.itemId != 4
              // ) {
              //   var addnewObject = {
              //     disabled: false,
              //     group: null,
              //     selected: false,
              //     text: "+ Add new",
              //     value: "addNew",
              //   };
              //   this.dropDownList.splice(0, 0, addnewObject);
              // }

              this.listSelected = list;
              this.$emit("input", this.listSelected);
              this.addNewValue = "";

              this.multipleSelect += 1;
            } 
          
        } else {
          this.$emit("displayLoader", false);
          if (response.data.message) {
            if (response.data.message.trim().length > 0) {
              this.notificationMessage = response.data.message;
            } else {
              this.notificationMessage = "Something went wrong";
            }
          } else {
            this.notificationMessage = "Something went wrong";
          }
          this.showNotificationBar(this.notificationMessage, "error");
          this.multipleSelect += 1;
          this.addNewValue = "";
        }
        this.$emit("displayLoader", false);
        // if(response.data.message.trim().length == 0){

        // }
      }
    },
    showNotificationBar(message, notificationType) {
      this.notificationMessage = message;
      this.notificationType = notificationType;
      this.notificationKey = "error-default";
      this.showNotification = true;
      this.addNewValue = "";
    },
  },
};
</script>
