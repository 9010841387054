<template>
  <div>
    <mds-modal
      :title="'Add New '+this.addEntityTypeName"
      :width="'600px'"
      v-model="toggle"
      action-required
    >
      <div style="width:70%;">
        <div>
          <div style="margin-bottom:10%">
            <span>
              <h3  style="margin:0;font-weight:normal">
              Parent entity is   <span class="sub-heading">{{ getSelectedEntity.entityname }}</span>
              </h3>
            </span>
          </div>
     
          <div style="margin-bottom:5%">
            
            
          <mds-form>
                 <!-- <mds-fieldset
              horizontal
              variation="radio-group"
              legend="New / Existing"
            >
              <mds-radio-button
                name="group1"
                v-model="type"
                value="New"
                @input="radioButtonChange()"
                >New</mds-radio-button
              >
              <mds-radio-button
                name="group1"
                v-model="type"
                value="Existing"
                @input="radioButtonChange()"
                >Existing</mds-radio-button
              >
            </mds-fieldset> -->

             <!-- <div v-if="type == 'Existing'" style="margin-bottom: 16px">
              <mds-select
                v-if="(entityType) => 1"
                label="Select Existing Template"
                v-model="existingEntity"
              >
                <option value="">Select</option>
                <option
                  v-for="entity in existingTemplateList"
                  :key="entity.entityid"
                  :value="entity.entityid"
                >
                  {{ entity.entityname }}
                </option>
              </mds-select>
            </div> -->
            <mds-input :label="'Name of New '+this.addEntityTypeName" :maxlength="enums.inputMaxLength"  v-model="newAddedEntityName"></mds-input>
          </mds-form>
          </div>
          
        </div>
      </div>
      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
            <button-component    :onClick="onCancelClick"
                                  buttonName="Cancel"
                                  buttonVariation="secondary"
                                  >
            </button-component>
            <button-component    :onClick="onAddClick"
                                  buttonName="Add"
                                   iconName="plus"
                                  buttonVariation="primary"
                                  :isDisabled="buttonDisable"
                                  >
            </button-component>
                </mds-button-container>
      </template>
    </mds-modal>
  </div>
</template>
<script>
import MdsModal from "@mds/modal";
import MdsInput from "@mds/input";
import MdsForm from "@mds/form";
import { MdsButtonContainer } from "@mds/button";
import ButtonComponent from '../ui_component/ButtonComponent.vue';
import { mapGetters,mapActions } from "vuex";
import Enums from "../../config/Enums.json";
// import MdsFieldset from "@mds/fieldset";
// import MdsRadioButton from "@mds/radio-button";
// import MdsSelect from "@mds/select";
export default {
  components: {
    // MdsSelect,
    // MdsFieldset,
    // MdsRadioButton,
    MdsModal,
    MdsInput,
    MdsForm,
    MdsButtonContainer,
    ButtonComponent
  },
  // props:{
  //   parentEntityDetails:{
  //     type:Object
  //   }
  // },
  data() {
    return {
      enums:Enums,
       type: "New",
      toggle: true,
      entityType: "",
      existingTemplateList: [],
      existingEntity: null,
      newAddedEntityName: "",
      inputLabel: "",
      addEntityTypeName:'',
      addEntityPayload:{
        "entitytypeid": 0,
        "parententitytype": null,
        "entitytype": null,
        "parententities": null,
        "childentities": null,
        "childtypes": null,
        "entityname": "",
        "parententityid": 0,
        "parententitytypeid": 0,
        "parententityname": null,
        "entityid": 0,
        "iscontadd": "False"
      },
      entityDropDownPayload: {
        entityid: 0,
        parententityid: 0,
        entitytypeid: 0,
      },
    };
  },
  computed:{
     ...mapGetters("entity", ["getEntities", "getSelectedEntity","getEntityTypes","getReferenceEntityTypesDropdown"]),
     buttonDisable(){
        if(this.newAddedEntityName.trim().length > 0 &&  this.type === "New"){
         return false
       }
       return true;
     }
  },  
  async mounted(){
    await this.getChildDetails(this.getSelectedEntity)
  },
  methods: {
    ...mapActions("entity", [
      "fetchDropdownListEntity",
    ]),
      radioButtonChange() {
     this.newAddedEntityName = "";
     this.existingEntity=null
      if(this.type == "Existing" && this.addEntityPayload.entitytypeid != null){
        this.getExistingTemplates()
      }
    },
    async getExistingTemplates() {
      this.onClickshowLoader = true;
      this.entityDropDownPayload.entitytypeid = this.addEntityPayload.entitytypeid;
      this.entityDropDownPayload.parententityid = null;
      this.entityDropDownPayload.entityid = null;
      var response = await this.fetchDropdownListEntity(
        this.entityDropDownPayload
      );
      if (response.status == 200) {
        this.existingTemplateList = response.data;
      }
      this.onClickshowLoader = false;
    },
    onCancelClick(){
       this.inputLabel = " ";
       this.entityType="";
       this.newAddedEntityName=""
        this.$emit('clicked',false)
    },
    onAddClick(){
      this.addEntityPayload.parententityid=parseInt(this.getSelectedEntity.entityid)
      this.addEntityPayload.entityname=this.newAddedEntityName
      this.$emit('entityAdded',this.addEntityPayload)
    },
    async getChildDetails(parentDetails){
       if(parentDetails){
         let childEntityTypeDetails=[]
         if(this.getEntityTypes.length > 0){
           childEntityTypeDetails = this.getEntityTypes.filter(entityType=>entityType.entitytypeid == parentDetails.entitytypeid)
         }else{
             childEntityTypeDetails = this.getReferenceEntityTypesDropdown.filter(entityType=>entityType.entitytypeid == parentDetails.entitytypeid)
         }
      if(childEntityTypeDetails[0].childentitytypedescription != null && childEntityTypeDetails[0].childentityid != 0){
      this.addEntityTypeName = childEntityTypeDetails[0].childentitytypedescription
      this.addEntityPayload.entitytypeid = childEntityTypeDetails[0].childentityid
      }
    }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.sub-heading {
  @include mds-level-5-heading($bold: true);
  color: $mds-text-color-primary;
}
</style>
