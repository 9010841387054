<template>
  <div id="bulk-edit-table-component">
    <table
      class="data-table"
      :key="tableComponentKey"
    >
      <thead>
        <tr>
          <th class="empty-header" v-if="validationError"></th>

          <!-- :class="[header.name == 'Entity Name' ? 'mds-data-table__header-cell--sorted-descending':'']" -->
          <th
            class="table-cell-header"
            v-for="(header, headerIndex) in tableHeaderDetails"
            :key="headerIndex"
            :style="header.name == 'Entity Name' ? 'width:300px;' : ''"
          >
            <div
              class="table-header-cell-inner"
              :style="
                header.datapointGroup.length > 0
                  ? 'border-bottom: 1px solid #1e1e1e;'
                  : ''
              "
              :class="
                header.datapointGroup.length > 0
                  ? 'table-header-cell-inner-group'
                  : ''
              "
            >
              <span class="table-header-cell-text" style="white-space: pre-wrap;">{{ header.name }}</span>
            </div>
            <div v-if="header.datapointGroup.length > 0">
              <table class="data-table" >
                <tr>
                  <th
                    class="table-cell-header"
                    v-for="(datapointGroupHeader,
                    datapointGroupHeaderIndex) in header.datapointGroup"
                    :key="datapointGroupHeaderIndex"
                    :style="
                datapointGroupHeaderIndex == 0
                  ? 'padding-left:0;'
                  : ''
              "
                  >
                    <div class="table-header-cell-inner">
                      <span class="table-header-cell-text" style="white-space: pre-wrap;">{{ datapointGroupHeader.name }}</span>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </table>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, dataIndex) in tableRowDataDetails" :key="dataIndex">
          <td
            v-if="validationError"
            :style="
              dataIndex == 0
                ? 'border-top:none'
                : 'border-top: solid 1px #e5e5e5;'
            "
            class="error-table-cell"
          >
          
              <!-- -->
            <div
              v-if="data.isError"
              :id="dataIndex + 'errorIconError'"
                @mouseover="mouseOverShowToolTip(data)"
               class="error-table-cell-div"
            >
              <mds-icon
                name="alert-fill"
                class="red-color"
                size="small"
              ></mds-icon>
              <mds-tooltip
                variation="error"
                class="red-color-background"
                :triggered-by="dataIndex + 'errorIconError'"
                :visible="data.showErrorToolTip"
                :position="['right-center']"
              >
                <div class="toolTipListDiv">
                  <mds-list-group>
                    <mds-list-group-item
                      v-for="(msg, indexMsg) in data.messages"
                      :key="indexMsg"
                      :text="msg"
                    ></mds-list-group-item>
                  </mds-list-group>
                </div>
              </mds-tooltip>
            </div>
          </td>
          <td
            class="table-cell"
            :style="
              dataIndex == 0
                ? 'border-top:none'
                : 'border-top: solid 1px #e5e5e5;'
            "
          >
            {{ data.entityName }}
          </td>
          <td
            class="table-cell"
            :style="
              dataIndex == 0
                ? 'border-top:none'
                : 'border-top: solid 1px #e5e5e5;'
            "
          >
            {{ getEntityTypeDetails(data.type) }}
          </td>
          <td
            class="table-cell"
            v-for="(datapoints, datapointIndex) in data.datapointdetails"
            :key="datapointIndex"
            :style="
              dataIndex == 0
                ? 'border-top:none'
                : 'border-top: solid 1px #e5e5e5;'
            "
            :class="
              datapoints.datapointgroup.length != 0 ? 'table-cell-group' : ''
            "
          >
            <div v-if="datapoints.datapointgroup.length != 0">
                <template 
                v-for="(groupDatapoints,groupDatapointIndex) in datapoints.datapointgroup">
              <tr
              v-if="groupDatapoints.showRow"
                style="display:flex"
                :key="groupDatapointIndex"
              >
              <template>
                
              </template>
                <td
                  class="table-cell"
                  style="width:200px;border:none;"
                  v-for="(datapointListGroup,
                  datapointListGroupIndex) in groupDatapoints.datapointlist"
                  :key="datapointListGroupIndex"
                >
                  <div v-if="datapointListGroup.enumid != null">
                    <div v-if="datapointListGroup.datapointgrouptypeid != 1 && datapointListGroup.arraylength != 1">
                      <div class="multi-select-div">
                        <multi-select
                          :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                          v-model="datapointListGroup.datapointvalue"
                          type="selectForEnum" 
                          :itemArray="getValue(datapointListGroup)"
                          :oldItemArray="getOldValues(datapointListGroup)"
                          :itemId="datapointListGroup.enumid"
                          @displayLoader="displayLoader"
                          @text="(v)=>datapointListGroup.datapointText=v"
                          @valueChange="onValueChange(datapointListGroup,datapointListGroup.datapointText,true),datapointListGroup.isError=false"
                          :datapointName="datapointListGroup.datapointname"
                          :isValidationError="datapointListGroup.isError"
                          :key="multiSelectEnumKey"
                          :isdisabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                        ></multi-select>
                      </div>
                    </div>
                    <div v-else>
                      <select-drop-down-sample
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                        :itemId="datapointListGroup.enumid"
                        :datapointName="datapointListGroup.datapointname"
                        :valueDetails="datapointListGroup.datapointvalue"
                        v-model="datapointListGroup.datapointvalue"
                        @valueChange="onValueChange(datapointListGroup,$event,false)"
                        type="selectForEnum"
                        @displayLoader="displayLoader"
                        @text="(v)=>datapointListGroup.datapointText=v"
                        :isValidationError="datapointListGroup.isError"
                        :key="selectEnumKey"
                        :isdisabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                      ></select-drop-down-sample>
                    </div>
                  </div>
                  <div v-else-if="datapointListGroup.datatypeenum == dataTypeIds.IdInteger">
                    <div v-if="datapointListGroup.datapointgrouptypeid != 1 && datapointListGroup.arraylength != 1">
                      <div class="multi-select-div">
                        <multi-select
                        :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                          v-model="datapointListGroup.datapointvalue" 
                          :itemArray="getValue(datapointListGroup)"
                          :oldItemArray="getOldValues(datapointListGroup)"
                          type="selectForReference"
                          :itemId="datapointListGroup.referencedentitytypeid"
                          @displayLoader="displayLoader"
                           @text="(v)=>datapointListGroup.datapointText=v"
                           @valueChange="onValueChange(datapointListGroup,datapointListGroup.datapointText,true),datapointListGroup.isError=false"
                          :datapointName="datapointListGroup.datapointname"
                          :isValidationError="datapointListGroup.isError"
                          :key="multiSelectRefKey"
                          :isdisabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                        ></multi-select>
                      </div>
                    </div>
                    <div v-else>
                      <select-drop-down-sample
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                        :itemId="datapointListGroup.referencedentitytypeid"
                        :datapointName="datapointListGroup.datapointname"
                        :valueDetails="datapointListGroup.datapointvalue"
                        v-model="datapointListGroup.datapointvalue"
                        type="selectForReference"
                        @valueChange="onValueChange(datapointListGroup,$event,false)"
                        @displayLoader="displayLoader"
                         @text="(v)=>datapointListGroup.datapointText=v"
                        :isValidationError="datapointListGroup.isError"
                        :key="selectRefKey"
                        :isdisabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                      ></select-drop-down-sample>
                    </div>
                  </div>
                  <div  v-else-if="datapointListGroup.datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE'">
                    <mds-input
                      hidden-label
                      type="date"
                      label="Date"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :value="datapointListGroup.datapointvalue"
                      @change="(v) =>(datapointListGroup.datapointvalue = onDateChange(v.target.value),onValueChange(datapointListGroup,v.target.value,false))"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-input>
                  </div>
                  <div  v-else-if="datapointListGroup.datapointname.toString().toUpperCase() == 'KEY DATE'">
                    <mds-input
                      hidden-label
                      type="date"
                      label="Date"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :value="datapointListGroup.datapointvalue"
                      @change="(v) =>(datapointListGroup.datapointvalue = onDateChange(v.target.value),onValueChange(datapointListGroup,v.target.value,false))"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-input>
                  </div>
                  <div v-else>
                    <mds-input
                      hidden-label
                      class="inputRequired"
                      label="Default"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :maxlength="enums.inputMaxLength"
                      :value="datapointListGroup.datapointvalue"
                      @change="(v) =>(datapointListGroup.datapointvalue = v.target.value,onValueChange(datapointListGroup,v.target.value,false))"
                      v-if="datapointListGroup.datatypeid == dataTypeIds.StringShort"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-input>
                    <mds-input
                      hidden-label
                      type="Number"
                      label="Default"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :maxlength="enums.inputMaxLength"
                      :value="datapointListGroup.datapointvalue"
                       onkeydown="return event.keyCode !== 69"
                      @change="(v) =>(datapointListGroup.datapointvalue = v.target.value,onValueChange(datapointListGroup,v.target.value,false))"
                      v-if="datapointListGroup.datatypeid == dataTypeIds.Number"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-input>
                    <mds-input
                      hidden-label
                      type="number"
                      class="inputRequired"
                      label="Integer"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :maxlength="enums.inputMaxLength"
                      :value="datapointListGroup.datapointvalue"
                      @change="(v) =>(datapointListGroup.datapointvalue = checkMax(v,datapointListGroup),onValueChange(datapointListGroup,v.target.value,false))"
                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                      v-if="datapointListGroup.datatypeid == dataTypeIds.Integer"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-input>
                    <mds-textarea
                      hidden-label
                      label="Add"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :value="datapointListGroup.datapointvalue"
                      @change="(v) =>(datapointListGroup.datapointvalue = v.target.value,onValueChange(datapointListGroup,v.target.value,false))"
                      v-if=" datapointListGroup.datatypeid == dataTypeIds.StringLong"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-textarea>
                    <mds-textarea
                      hidden-label
                      label="Add"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :value="datapointListGroup.datapointvalue"
                      @change="(v) =>(datapointListGroup.datapointvalue = v.target.value,onValueChange(datapointListGroup,v.target.value,false))"
                      v-if=" datapointListGroup.datatypeid == dataTypeIds.IdInteger"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-textarea>
                    <mds-input
                      hidden-label
                      type="date"
                      label="Date"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :value="datapointListGroup.datapointvalue"
                      @change="(v) =>(datapointListGroup.datapointvalue = onDateChange(v.target.value),onValueChange(datapointListGroup,v.target.value,false))"
                      v-if="datapointListGroup.datatypeid == dataTypeIds.Date"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-input>
                    <mds-input
                      hidden-label
                      type="time"
                      label="Time"
                      step="1"
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapointListGroup.datapointname.replace(/ /g,'_')+'_Value_'+datapointListGroup.arrayindex"
                      :value="datapointListGroup.datapointvalue"
                      @change="(v) => (datapointListGroup.datapointvalue = v.target.value,onValueChange(datapointListGroup,v.target.value,false))"
                      v-if="datapointListGroup.datatypeid == dataTypeIds.Time"
                      :error="datapointListGroup.isError"
                      :disabled="datapointListGroup.targetdatapoint || disableFields(datapointListGroup)"
                    ></mds-input>
                  </div>
                </td>
                <td
                  v-if="groupDatapointIndex != 0"
                  class="table-cell delete-icon-cell"
                >
                  <mds-button
                    variation="icon-only"
                    icon="trash"
                    :disabled="disableAddRemove(datapoints.datapointgroup,data.datapointdetails)"
                    @click="deleteGroup(groupDatapointIndex, data.entityId)"
                  ></mds-button>
                </td>
              </tr>
              </template>
              
               <mds-button
                    variation="secondary"
                    size="small"
                    class="add-another-button"
                    :disabled="disableAddRemove(datapoints.datapointgroup,data.datapointdetails)"
                    @click="addAnotherGroup(datapoints.datapointgroup,data.entityId)"
                    >Add Another</mds-button>
            </div>

            <div v-else>
              <div v-if="datapoints.enumid != null">
                <div v-if="datapoints.datapointgrouptypeid != 1 && datapoints.arraylength != 1">
                  <div class="multi-select-div">
                    <multi-select
                    :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                      v-model="datapoints.values"
                      type="selectForEnum"
                      :itemArray="getValue(datapoints)"
                      :oldItemArray="getOldValues(datapoints)"
                      :itemId="datapoints.enumid"
                      @displayLoader="displayLoader"
                      @text="(v)=>datapoints.datapointText=v"
                      @valueChange="onValueChange(datapoints,datapoints.datapointText,true),datapoints.isError=false"
                      :datapointName="datapoints.datapointname"
                      :isValidationError="datapoints.isError"
                      :key="multiSelectEnumKey"
                      :isdisabled="datapoints.targetdatapoint || disableFields(datapoints)"
                    >
                    </multi-select>
                  </div>
                </div>
                <div v-else>
                  <select-drop-down-sample
                  :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                    :itemId="datapoints.enumid" 
                    :datapointName="datapoints.datapointname"
                    :valueDetails="datapoints.datapointvalue"
                    v-model="datapoints.datapointvalue"
                    @valueChange="onValueChange(datapoints,$event,false)"
                    @text="(v)=>datapoints.datapointText=v"
                    type="selectForEnum"
                    @displayLoader="displayLoader"
                    :isValidationError="datapoints.isError"
                    :key="selectEnumKey"
                    :isdisabled="datapoints.targetdatapoint || disableFields(datapoints)"
                  ></select-drop-down-sample>
                </div>
              </div>
              <div v-else-if="datapoints.datatypeenum == dataTypeIds.IdInteger">
                <div v-if="datapoints.datapointgrouptypeid != 1 && datapoints.arraylength != 1">
                  <div class="multi-select-div">
                    <multi-select
                      :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                      v-model="datapoints.datapointvalue"
                      type="selectForReference"
                      :itemArray="getValue(datapoints)"
                      :oldItemArray="getOldValues(datapoints)"
                      :itemId="datapoints.referencedentitytypeid"
                       @text="(v)=>datapoints.datapointText=v"
                       @valueChange="onValueChange(datapoints,datapoints.datapointText,true),datapoints.isError=false"
                      @displayLoader="displayLoader"
                      :datapointName="datapoints.datapointname"
                      :isValidationError="datapoints.isError"
                      :key="multiSelectRefKey"
                      :isdisabled="datapoints.targetdatapoint || disableFields(datapoints)"
                    ></multi-select>
                  </div>
                </div>
                <div v-else>
                  <select-drop-down-sample
                    :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                    :itemId="datapoints.referencedentitytypeid"
                    :datapointName="datapoints.datapointname"
                    :valueDetails="datapoints.datapointvalue"
                    v-model="datapoints.datapointvalue"
                    @valueChange="onValueChange(datapoints,$event,false)"
                    type="selectForReference"
                     @text="(v)=>datapoints.datapointText=v"
                    @displayLoader="displayLoader"
                    :isValidationError="datapoints.isError"
                    :key="selectRefKey"
                    :isdisabled="datapoints.targetdatapoint || disableFields(datapoints)"
                  ></select-drop-down-sample>
                </div>
              </div>
              <div v-else>
                <mds-input
                  hidden-label
                  :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                  class="inputRequired"
                  :error="datapoints.isError"
                  label="Default"
                  :maxlength="enums.inputMaxLength"
                  :value="datapoints.datapointvalue"
                  @change="(v) => (datapoints.datapointvalue = v.target.value,onValueChange(datapoints,v.target.value,false))"
                  v-if="datapoints.datatypeid == dataTypeIds.StringShort"
                  :disabled="datapoints.targetdatapoint || disableFields(datapoints)"
                ></mds-input>
                <mds-input
                  hidden-label
                  :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                  type="Number"
                  label="Default"
                  :maxlength="enums.inputMaxLength"
                  :error="datapoints.isError"
                  :value="datapoints.datapointvalue"
                   onkeydown="return event.keyCode !== 69"
                  @change="(v) => (datapoints.datapointvalue = v.target.value,onValueChange(datapoints,v.target.value,false))"
                  v-if="datapoints.datatypeid == dataTypeIds.Number"
                  :disabled="datapoints.targetdatapoint || disableFields(datapoints)"
                ></mds-input>
                <mds-input
                  hidden-label
                  type="number"
                  :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                  class="inputRequired"
                  label="Integer"
                  :maxlength="enums.inputMaxLength"
                  :error="datapoints.isError"
                  :value="datapoints.datapointvalue"
                  @change="(v) => (datapoints.datapointvalue = checkMax(v,datapoints),onValueChange(datapoints,v.target.value,false))"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                  v-if="datapoints.datatypeid == dataTypeIds.Integer"
                  :disabled="datapoints.targetdatapoint || disableFields(datapoints)"
                ></mds-input>
                <mds-textarea
                  hidden-label
                  label="Add"
                  :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                  :error="datapoints.isError"
                  :value="datapoints.datapointvalue"
                  @change="(v) => (datapoints.datapointvalue = v.target.value,onValueChange(datapoints,v.target.value,false))"
                  v-if="datapoints.datatypeid == dataTypeIds.StringLong"
                  :disabled="datapoints.targetdatapoint || disableFields(datapoints)"
                ></mds-textarea>
                <mds-textarea
                  hidden-label
                  label="Add"
                  :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                  :error="datapoints.isError"
                  :value="datapoints.datapointvalue"
                  @change="(v) => (datapoints.datapointvalue = v.target.value,onValueChange(datapoints,v.target.value,false))"
                  v-if="datapoints.datatypeid == dataTypeIds.IdInteger"
                  :disabled="datapoints.targetdatapoint || disableFields(datapoints)"
                ></mds-textarea>
                <mds-input
                  hidden-label
                  type="date"
                  label="Date"
                  :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                  :error="datapoints.isError"
                  :value="datapoints.datapointvalue"
                  @change="(v) => (datapoints.datapointvalue = onDateChange(v.target.value),onValueChange(datapoints,v.target.value,false))"
                  v-if="datapoints.datatypeid == dataTypeIds.Date"
                  :disabled="datapoints.targetdatapoint || disableFields(datapoints)"
                ></mds-input>
                <mds-input
                  hidden-label
                  type="time"
                  label="Time"
                  :id="data.entityName.replace(/ /g,'_')+'_'+datapoints.datapointname.replace(/ /g,'_')+'_Value_'+datapoints.arrayindex"
                  step="1"
                  :error="datapoints.isError"
                  :value="datapoints.datapointvalue"
                  @change="(v) => (datapoints.datapointvalue = v.target.value,onValueChange(datapoints,v.target.value,false))"
                  v-if="datapoints.datatypeid == dataTypeIds.Time"
                  :disabled="datapoints.targetdatapoint || disableFields(datapoints)"
                ></mds-input>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="showLoader">
      <loader-component loaderSize="large"></loader-component>
    </div>
    <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :dismissDelay="3000"
      @close="showNotification = false"
    ></notification-component>
  </div>
</template>
<script>
import Enums from "../../config/Enums.json";
import MdsInput from "@mds/input";
import MdsTextarea from "@mds/textarea";
// import ButtonComponent from "../ui_component/ButtonComponent.vue";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import { mapGetters, mapActions } from "vuex";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsIcon from "@mds/icon";
import MdsTooltip from "@mds/tooltip";
import { MdsButton } from "@mds/button";
import SelectDropDownSample from '../ui_component/selectDropDownSample.vue';
import MultiSelect from '../ui_component/MultiSelect.vue';
import NotificationComponent from "../ui_component/notificationComponent.vue";
import {getAutoMappingDetails} from "../../services/add_investment_service.js";
// import MdsForm from '@mds/form'
// import MdsFieldset from '@mds/fieldset'
export default {
  components: {
    MdsInput,
    MdsTextarea,
    // ButtonComponent,
    LoaderComponent,
    MdsIcon,
    MdsListGroup,
    MdsListGroupItem,
    MdsTooltip,
    MdsButton,
    SelectDropDownSample,
    MultiSelect,
    NotificationComponent
    // MdsForm,
    // MdsFieldset
  },
  props: {
    tableHeaders: {
      required: true,
    },
    tableRowData: {
      required: true,
    },
    validationError: {
      required: true,
    },
    isGroupDatapoints: {
      default: false,
    },
  },
  data() {
    return {
      variationKey:'',
      notificationType:'',
      showNotification:false,
      notificationMessage:null,
      notificationKey: "",
      tableComponentKey: 0,
      entityTypes: [],
      showLoader: false,
      dataTypeIds: Enums.dataTypes,
      selectRefKey: 0,
      selectEnumKey: 0,
      enums:Enums,
      multiSelectRefKey: 0,
      multiSelectEnumKey:0,
      tableHeaderDetails: [],
      tableRowDataDetails: this.tableRowData,
    };
  },
  computed: {
    ...mapGetters("entity", ["getEntityTypes"]),
      ...mapGetters("dropDowns", ["getEnumDropDowns","getReferenceDropDowns"]),
  },
  async mounted() {
    this.showLoader = true;
    this.tableHeaderDetails = this.tableHeaders
    this.tableRowDataDetails =this.tableRowData
    if (this.getEntityTypes.length > 0) {
      this.entityTypes = this.getEntityTypes;
    } else {
      await this.fetchEntityTypes();
      this.entityTypes = this.getEntityTypes;
    }
    this.onSortData()
    this.showLoader = false;
  },
  methods: {
    ...mapActions("entity", ["fetchEntityTypes"]),
    checkMax(event,dataPoint){
      if(event.target.value > 2147483647){
         this.notificationType = "warning"
          this.notificationMessage="Value must be less than or equal to 2147483647"
          this.notificationKey = "warning-default"
          this.showNotification = true
        return dataPoint.datapointvalue
      }else{
        return event.target.value
      }
    },
     onDateChange(date){
      if(date.length == 0){
        return null
      }
      return date
    },
    mouseOverShowToolTip(data){
      for(let i=0;i<this.tableRowDataDetails.length;i++){
        if(this.tableRowDataDetails[i].entityId == data.entityId){
         this.tableRowDataDetails[i].showErrorToolTip = true
        }else{
        this.tableRowDataDetails[i].showErrorToolTip = false
        }
      }
    },
    disableAddRemove(dataPointGroup,entityDetails){
      if(!entityDetails[0].isdraft){
        for(let j=0;j<dataPointGroup.length;j++){
          for(let i=0;i<dataPointGroup[j].datapointlist.length;i++){
            if((dataPointGroup[j].datapointlist[i].validitystateid == this.enums.ValidityStates.NeedsApproval || 
             dataPointGroup[j].datapointlist[i].validitystateid==this.enums.ValidityStates.RejectedForEdit || dataPointGroup[j].datapointlist[i].disabledatapoint) && !dataPointGroup[j].datapointlist[i].isdraft ){
              return true
            }else{
              if(j == dataPointGroup[j].length-1){
                return false
              }
            }

          }
        }
          
      }else{
        return false
      }
      
    },
    disableFields(datapoint){
      if(datapoint.isna){
        return true
      }else if(datapoint.isdraft){
        return false
      }else if(datapoint.disabledatapoint){
        return true
      }else{
        if(datapoint.validitystateid == null || datapoint.validitystateid == 0){
          return false
        }else if(datapoint.validitystateid == this.enums.ValidityStates.RejectedForEdit || datapoint.validitystateid==this.enums.ValidityStates.NeedsApproval){
          return true
        }else{
          return false
        }
      }
         
      
    },
    onSortData(){
      var headers=[]
      for(let h=0;h<this.tableHeaderDetails.length;h++){
        if(this.tableHeaderDetails[h].datapointGroup.length > 0){
          headers=this.tableHeaderDetails[h].datapointGroup
          for(let i=0;i<this.tableRowDataDetails.length;i++){
            for(let j=0;j<this.tableRowDataDetails[i].datapointdetails.length;j++){
                if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length >0){
                  for(let k=0;k<this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length;k++){
                    this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist.sort(function(a,b){
                      return headers.map(function(e){
                      return e.datapointid;
                      }).indexOf(a.datapointid)-headers.map(function(e){
                      return e.datapointid;
                      }).indexOf(b.datapointid)
                    })
                  }
                }
            }
          }
        }
      }
       
    },
    async onValueChange(datapoints,event,isMultiSelect){
      let value=''
      if(isMultiSelect){
        if(event != null){
          value =event.replace(/;/g, '#$%')
        }else{
          value = event
        }
      }else{
        value = event
      }

      if(datapoints.sourcedatapoint){
        this.showLoader=true
        var response = await getAutoMappingDetails(datapoints.datapointid,value)
        if(!response.isError){ 
          let groupedObjects = this.groupBy(response.data,"targetdatapointid")
             let mergedUniqueList = Array.from(response.data.reduce((entryMap, e) => entryMap.set(e.targetdatapointid, {...entryMap.get(e.targetdatapointid)||{}, ...e}),new Map()).values());
           for(let i=0;i<this.tableRowDataDetails.length;i++){
        if(this.tableRowDataDetails[i].entityId == datapoints.entityid){
          for(let j=0;j<this.tableRowDataDetails[i].datapointdetails.length;j++){
            if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length >0){
              for(let k=0;k<this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length;k++){
                if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].arrayindex == datapoints.arrayindex){
                  for(let m=0;m<this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist.length;m++){
                    for(let mu=0;mu<mergedUniqueList.length;mu++){
                          if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datapointid == mergedUniqueList[mu].targetdatapointid){
                            if((this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].enumid != null || this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datatypeenum == this.dataTypeIds.IdInteger) && this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].arraylength != 1 && this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datapointgrouptypeid != 1){
                              let dropDownObjectDetails=[]
                              if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datatypeenum == this.dataTypeIds.IdInteger){
                                dropDownObjectDetails=this.getReferenceDropDowns.filter(e=>e.refrenceid == this.tableRowDataDetails[i].datapointdetails[j].referencedentitytypeid)
                              }else if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].enumid != null){
                                dropDownObjectDetails=this.getEnumDropDowns.filter(e=>e.enumid == this.tableRowDataDetails[i].datapointdetails[j].enumid)
                              }
                                if(mergedUniqueList[mu].targetdatapointid == groupedObjects[mergedUniqueList[mu].targetdatapointid][0].targetdatapointid){
                                    let groupObject=groupedObjects[mergedUniqueList[mu].targetdatapointid]
                                    if(groupObject.length > 1 || (this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].enumid!=null && this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].arraylength!=1 && this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datapointgrouptypeid !=1)){
                                      let targetValueText =''
                                      let targetValues=[]
                                      for(let l=0;l<groupObject.length;l++){
                                        if(targetValueText.length == 0){
                                          targetValueText=groupObject[l].targetvalue
                                        }else{
                                          targetValueText=targetValueText+";"+groupObject[l].targetvalue
                                        }
                                        if(groupObject[l].targetvalue == "Not Applicable"){
                                          targetValues=null
                                        }else{
                                          if(groupObject[l].targetvalue != null){
                                            let valueObject = dropDownObjectDetails[0].values.filter(v=>v.text==groupObject[l].targetvalue)
                                            targetValues.push(valueObject[0].value)
                                          }
                                        }
                                        
                                      }
                                      mergedUniqueList[mu].targetvalue=targetValues
                                      mergedUniqueList[mu]["targetValueText"]=targetValueText
                                }else{
                                  mergedUniqueList[mu]["targetValueText"]=mergedUniqueList[mu].targetvalue
                                }
                              }
                              }
                          }
                        }
                    for(let n=0;n<mergedUniqueList.length;n++){
                      if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datapointid == mergedUniqueList[n].targetdatapointid){
                        
                        if(mergedUniqueList[n].targetvalue == "Not Applicable"){
                          this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datapointvalue=null
                        }else{
                          if((this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].enumid != null || this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datatypeenum == this.dataTypeIds.IdInteger) && this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].arraylength != 1 && this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datapointgrouptypeid != 1){
                      this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datapointText=mergedUniqueList[n].targetValueText
                          this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].values=mergedUniqueList[n].targetvalue
                    }else{
                      this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].datapointlist[m].datapointvalue=mergedUniqueList[n].targetvalue
                    }
                        }
                         this.multiSelectEnumKey+=1
                        this.multiSelectRefKey+=1
                        this.selectRefKey+=1
                        this.selectEnumKeySec+=1
                        this.selectEnumKey+=1
                      }
                    }
                  }
                }
              }
            }else{
              for(let m=0;m<mergedUniqueList.length;m++){
               if(this.tableRowDataDetails[i].datapointdetails[j].datapointid == mergedUniqueList[m].targetdatapointid){
                 if((this.tableRowDataDetails[i].datapointdetails[j].enumid != null || this.tableRowDataDetails[i].datapointdetails[j].datatypeenum == this.dataTypeIds.IdInteger) && this.tableRowDataDetails[i].datapointdetails[j].arraylength != 1 && this.tableRowDataDetails[i].datapointdetails[j].datapointgrouptypeid != 1){
                   let dropDownObject=[]
                   if(this.tableRowDataDetails[i].datapointdetails[j].datatypeenum == this.dataTypeIds.IdInteger){
                     dropDownObject=this.getReferenceDropDowns.filter(e=>e.refrenceid == this.tableRowDataDetails[i].datapointdetails[j].referencedentitytypeid)
                   }else if(this.tableRowDataDetails[i].datapointdetails[j].enumid != null){
                     dropDownObject=this.getEnumDropDowns.filter(e=>e.enumid == this.tableRowDataDetails[i].datapointdetails[j].enumid)
                   }
                      if(mergedUniqueList[m].targetdatapointid == groupedObjects[mergedUniqueList[m].targetdatapointid][0].targetdatapointid){
                          let groupObject=groupedObjects[mergedUniqueList[m].targetdatapointid]
                          if(groupObject.length > 1 || (this.tableRowDataDetails[i].datapointdetails[j].enumid!=null && this.tableRowDataDetails[i].datapointdetails[j].arraylength!=1 && this.tableRowDataDetails[i].datapointdetails[j].datapointgrouptypeid !=1)){
                            let targetValueText =''
                            let targetValues=[]
                            for(let l=0;l<groupObject.length;l++){
                              if(targetValueText.length == 0){
                                targetValueText=groupObject[l].targetvalue
                              }else{
                                targetValueText=targetValueText+";"+groupObject[l].targetvalue
                              }
                              if(groupObject[l].targetvalue == "Not Applicable"){
                                targetValues=null
                              }else{
                                if(groupObject[l].targetvalue != null){
                                  let valueObject = dropDownObject[0].values.filter(v=>v.text==groupObject[l].targetvalue)
                                  targetValues.push(valueObject[0].value)
                                }
                              }
                              
                            }
                            mergedUniqueList[m].targetvalue=targetValues
                            mergedUniqueList[m]["targetValueText"]=targetValueText
                      }else{
                        mergedUniqueList[m]["targetValueText"]=mergedUniqueList[m].targetvalue
                      }
                    }
                  }
               }
             }
              for(let k=0;k<mergedUniqueList.length;k++){
                if(this.tableRowDataDetails[i].datapointdetails[j].datapointid == mergedUniqueList[k].targetdatapointid){
                  
                  if(mergedUniqueList[k].targetvalue == "Not Applicable"){
                    this.tableRowDataDetails[i].datapointdetails[j].datapointvalue=null
                  }else{
                    if((this.tableRowDataDetails[i].datapointdetails[j].enumid != null || this.tableRowDataDetails[i].datapointdetails[j].datatypeenum == this.dataTypeIds.IdInteger) && this.tableRowDataDetails[i].datapointdetails[j].arraylength != 1 && this.tableRowDataDetails[i].datapointdetails[j].datapointgrouptypeid != 1){
                      this.tableRowDataDetails[i].datapointdetails[j].datapointText=mergedUniqueList[k].targetValueText
                          this.tableRowDataDetails[i].datapointdetails[j].values=mergedUniqueList[k].targetvalue
                    }else{
                     this.tableRowDataDetails[i].datapointdetails[j].datapointvalue=mergedUniqueList[k].targetvalue
                    }
                  }
                   this.multiSelectEnumKey+=1
                    this.multiSelectRefKey+=1
                    this.selectRefKey+=1
                    this.selectEnumKeySec+=1
                    this.selectEnumKey+=1
                }
              }
            }
          }
        }
      }
        }
     
        
         this.showLoader=false
      }
      // datapointid
      // datapointvalue
      
    },
    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    addAnotherGroup(group, entityId) {
      var groupDetails = [];
      for (var g = 0; g < group[0].datapointlist.length; g++) {
        var datapointDetails = {
          arrayindex:0,
          arraylength: group[0].datapointlist[g].arraylength,
          datapointgroupid: group[0].datapointlist[g].datapointgroupid,
          datapointgroups: group[0].datapointlist[g].datapointgroups,
          datapointgrouptypeid: group[0].datapointlist[g].datapointgrouptypeid,
          datapointid: group[0].datapointlist[g].datapointid,
          datapointname: group[0].datapointlist[g].datapointname,
          datapointvalue: null,
          datatypeenum: group[0].datapointlist[g].datatypeenum,
          datatypeid: group[0].datapointlist[g].datatypeid,
          entityid: group[0].datapointlist[g].entityid,
          entityname: group[0].datapointlist[g].entityname,
          entitytypeid: group[0].datapointlist[g].entitytypeid,
          enumid: group[0].datapointlist[g].enumid,
          groupname: group[0].datapointlist[g].groupname,
          referencedentitytypeid: group[0].datapointlist[g].referencedentitytypeid,
          sourcedatapoint: group[0].datapointlist[g].sourcedatapoint,
          targetdatapoint: group[0].datapointlist[g].targetdatapoint
        };
        groupDetails.push(datapointDetails);
      }
      for (var i = 0; i < this.tableRowDataDetails.length; i++) {
        if (this.tableRowDataDetails[i].entityId == entityId) {
          for (var j = 0;j < this.tableRowDataDetails[i].datapointdetails.length;j++) {
            if (this.tableRowDataDetails[i].datapointdetails[j].entityid == entityId){
              for(let m=0;m<groupDetails.length;m++){
                groupDetails[m].arrayindex=this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length
              }
              var obj = {
                arrayindex:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length,
                action: "Add",
                showRemoveButton:true,
                showRow: true,
                datapointlist: groupDetails,
              };
              this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.push(obj);
              this.tableComponentKey++;
            }
          }
        }
      }
    },
   async deleteGroup(index, entityId) {
      for (var i = 0; i < this.tableRowDataDetails.length; i++) {
        if (this.tableRowDataDetails[i].entityId == entityId) {
          for (var j = 0;j < this.tableRowDataDetails[i].datapointdetails.length;j++) {
            if (this.tableRowDataDetails[i].datapointdetails[j].entityid ==entityId) {
              if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length > 1){
                  
                for(var k=0;k<this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length;k++){
                    if(k == index){
                        if ( this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length == 1) {
                              await this.addNewGroupOnDelete(index, entityId)
                              if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].action == "Add"){
                                  this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.splice(index, 1);
                              }else{
                                if(this.checkGroupCountOnDelete(this.tableRowDataDetails[i].datapointdetails[j]) > 1){
                                  this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].action = "Delete"
                                  this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].showRow = false

                                }else{
                                  this.notificationType = "warning"
                                  this.notificationMessage="Can't remove all of these."
                                  this.notificationKey = "warning-default"
                                  this.showNotification = true
                                }
                              }
                            }else{
                                
                                if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].action == "Add"){
                                   if(this.checkGroupCountOnDelete(this.tableRowDataDetails[i].datapointdetails[j]) > 1){
                                        this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.splice(index, 1);
                                      if(this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length == 1 && this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].action == "Delete"){
                                          await this.addNewGroupOnDelete(index, entityId)
                                      }
                                      }else{
                                        this.notificationType = "warning"
                                        this.notificationMessage="Can't remove all of these."
                                        this.notificationKey = "warning-default"
                                        this.showNotification = true
                                      }
                                      
                                  }else{
                                      if(this.checkGroupCountOnDelete(this.tableRowDataDetails[i].datapointdetails[j]) > 1){
                                        this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].action = "Delete"
                                        this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].showRow = false
                                      }else{
                                        this.notificationType = "warning"
                                        this.notificationMessage="Can't remove all of these."
                                        this.notificationKey = "warning-default"
                                        this.showNotification = true
                                      }
                                      // this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].action = "Delete"
                                      // this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[k].showRow = false
                                  }
                            }
                    }
                }
                for(var ai=0;ai<this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length;ai++){
                  this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[ai].arrayindex = ai
                }
                this.tableComponentKey++;
              }else{
                this.notificationType = "warning"
                this.notificationMessage="Can't remove all of these."
                this.notificationKey = "warning-default"
                this.showNotification = true
              }
            }
          }
        }
      }
    },
    checkGroupCountOnDelete(datapointDetails){
      var count=0
      for(let i=0;i<datapointDetails.datapointgroup.length;i++){
          if(datapointDetails.datapointgroup[i].action == "Update" || datapointDetails.datapointgroup[i].action == "Add"){
            count++
          }
        }
        return count
    },
    addNewGroupOnDelete(index, entityId){
         for (var i = 0; i < this.tableRowDataDetails.length; i++) {
        if (this.tableRowDataDetails[i].entityId == entityId) {
          for (var j = 0;j < this.tableRowDataDetails[i].datapointdetails.length;j++) {
            if (this.tableRowDataDetails[i].datapointdetails[j].entityid ==entityId) {
            //   for(var k=0;k<this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length;k++){
                //   if(k == index){
                              var groupDetails=[]
                                for (var g = 0; g < this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist.length; g++) {
                                    var datapointDetails = {
                                        arrayindex:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length,
                                        arraylength:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].arraylength,
                                        datapointgroupid:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].datapointgroupid,
                                        datapointgroups:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].datapointgroups,
                                        datapointgrouptypeid:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].datapointgrouptypeid,
                                        datapointid:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].datapointid,
                                        datapointname:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].datapointname,
                                        datapointvalue: null,
                                        datatypeenum:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].datatypeenum,
                                        datatypeid:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].datatypeid,
                                        entityid:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].entityid,
                                        entityname:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].entityname,
                                        entitytypeid:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].entitytypeid,
                                        enumid:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].enumid,
                                        groupname:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].groupname,
                                        referencedentitytypeid:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].referencedentitytypeid,
                                        sourcedatapoint: this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].sourcedatapoint,
                                        targetdatapoint: this.tableRowDataDetails[i].datapointdetails[j].datapointgroup[0].datapointlist[g].targetdatapoint
                                        };
                                        groupDetails.push(datapointDetails);
                                    }
                                        var obj = {
                                            arrayindex:this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.length,
                                            action: "Add",
                                            showRow: true,
                                            showRemoveButton:true,
                                            datapointlist: groupDetails,
                                        };
                                        this.tableRowDataDetails[i].datapointdetails[j].datapointgroup.push(obj)
                                        
                                       
                                        
                                        this.tableComponentKey++;
                          
                //   }
            //   }
              this.tableComponentKey++;
            }
          }
        }
      }
    },
    getValue(dataPoint) {
      if (dataPoint.values != null) {
        if(dataPoint.values[0] == 0){
          return null
        }else{
           return dataPoint.values
           }
        // return dataPoint.values;
      } else {
        return dataPoint.value;
      }
    },
    getOldValues(dataPoint) {
      if (dataPoint.oldValues != null) {
        if(dataPoint.oldValues[0] == 0){
          return null
        }else{
           return dataPoint.oldValues
           }
        // return dataPoint.oldValues;
      } else {
        return dataPoint.oldvalue;
      }
    },
    getEntityTypeDetails(id) {
      if (id != 0) {
        for (var i = 0; i < this.entityTypes.length; i++) {
          if (this.entityTypes[i].entitytypeid == id) {
            return this.entityTypes[i].entitytypedescription;
          }
        }
      } else {
        return "Legal Entity";
      }
    },
    addDataPoints() {},
    displayLoader(value) {
      this.showLoader = value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.data-table {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  border-collapse: collapse;
  border-spacing: 0;
  color: #1e1e1e;
  width: max-content;
  position: relative;
}
.empty-header{
width:25px;
}
.table-cell-header {
  border-top: 0;
  padding: 0 8px;
  position: relative;
  vertical-align: bottom;
  width: 200px;
}
.table-cell-header-width {
  width: 200px;
}
.table-header-cell-inner {
  align-items: flex-end;
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
  padding-bottom: 2px;
}
.table-header-cell-text {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-weight: 600;
  color: #5e5e5e;
  margin: 0 $mds-space-half-x 0 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
.table-cell {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  border-top: solid 1px #e5e5e5;
  padding: 4px 8px;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: top;
  position: relative;
}
.table-cell-group {
  padding: 0;
}
.error-table-cell{
  float: left;
}
.error-table-cell-div{
  margin-top: 5px;
}
.add-another-button{
margin-right: 5px;
float: right;
}
.delete-icon-cell{
border:none;
text-align: center;
width: max-content;
display: flex;
justify-content: center;
align-items: center;
}
.multi-select-div{
width: 180px;
}
.toolTipListDiv{
max-height:400px;overflow:auto;
}
.table-header-cell-inner-group {
  margin-bottom: 5px !important;
}
.red-color {
  @include mds-icon-color(#ff0000);
  color: #ff0000;
}
.red-color-background {
  background-color: #ff0000;
  background: #ff0000;
  padding-right: 1px;
}
.red-color-background::after {
  border-right: 8px solid #ff0000 !important;
}

::v-deep .mds-list-group__link___VueMDS3Demo{
  color: white !important;
}

// #bulk-edit-table-component::v-deep .mds-tag___VueMDS3Demo .mds-tag__text___VueMDS3Demo {
//     display: block;
//     height: 26px;
//     width: 100px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }
// #bulk-edit-table-component::v-deep .mds-combo-box___VueMDS3Demo .mds-combo-box__result-list___VueMDS3Demo{
//     position: fixed;
//     z-index: 100;
//     top: unset!important;
//     width: 175px;
//     height: 200px;
// }
.table-header-cell-inner {
  // Ascending/descending header styles, uses before content for the sorting indicator icon
  .mds-data-table__header-cell--sorted-descending &,
  .mds-data-table__header-cell--sorted-ascending & {
    border-bottom: 1px solid $mds-text-color-primary;

    &::before {
      bottom: -1px;
      content: "";
      display: block;
      height: 21px;
      position: absolute;
      width: 19px;
    }

    .mds-data-table__header-cell-button {
      color: $mds-text-color-primary;
    }
  }

  // Descending sort icon
  .mds-data-table__header-cell--sorted-descending & {
    &::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOXB4IiBoZWlnaHQ9IjZweCIgYXJpYS1oaWRkZW49InRydWUiPjxnIGZpbGwtcnVsZT0ibm9uemVybyIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzFlMWUxZSIgZD0iTTAgMGgxMEw0Ljk5OCA1Ljl6Ii8+PHBhdGggZmlsbD0iI2FiYWJhYiIgZD0iTTkgNS45aDEwTDEzLjk5OCAweiIvPjwvZz48L3N2Zz4=");
    }
  }
}
</style>
