<template>
<!--  -->
  <div id="bulk-edit-table-template" @click="windowClick()">
    <mds-layout-grid>
    <mds-row style="margin: 0;display: grid;">
    <div>
      <div class="breadCrumsDiv">
        <span class="breadCrumsClickable" @click="onBreadCrumsClick('home')">Home</span> / <span class="breadCrumsClickable" @click="onBreadCrumsClick('entities')">Entities</span> / <span>Bulk Edit</span>
      </div>
      <div>
        <h2 class="template-header">Bulk Edit</h2>
      </div>
      <div style="width:100%;border-bottom: 1px solid #CCCCCC;">
        <div style="display:flex">
            <p class="flex-buttons headerText"  v-if="getSelectedTemplateId!=0" >{{getSelectedTemplateId.templatename}}</p>
          <div v-if="getSelectedTemplateId!=0" class="vertical-line"></div>
          <button-component
            class="flex-buttons"
            :onClick="addDatapoints"
            buttonName="Add Data Points"
            buttonVariation="flat"
            iconName="plus"
            buttonSize="large"
            :disabled="disableAddDataPoints"
          >
          </button-component>
          <button-component
            class="flex-buttons"
            :onClick="showSaveDialog"
            buttonName="Save Template"
            buttonVariation="flat"
            iconName="save"
            buttonSize="large"
          >
          </button-component>
          <div class="vertical-line"></div>
          <button-component
            class="flex-buttons"
            :onClick="downloadTemplate"
            buttonName="Download Template"
            buttonVariation="flat"
            iconName="download"
            buttonSize="large"
          >
          </button-component>
          <!-- <input
            class="file-upload-container"
            type="file"
            ref="fileUploadContainer"
            accept=".xlsx, .xls, .csv, .tsv"
            hidden
          />
          <button-component
            class="flex-buttons"
            :onClick="uploadFile"
            buttonName="Upload Data"
            buttonVariation="flat"
            iconName="upload"
            buttonSize="large"
          >
          </button-component> -->
        </div>
      </div>
    </div>
    <div
      class="table-div"
      style="width: 100%;height: 450px;
    overflow: scroll;"
    @scroll="windowClick()"
    
    > 
    <!--  -->
      <bulk-edit-table-component
      
        v-if="!showLoader"
        :tableHeaders="tableHeader"
        :validationError="isValidationError"
        :tableRowData="tableData"
        :key="tableComponentKey"
        :isGroupDatapoints="showGroupDatapoints"
      ></bulk-edit-table-component>
    </div>
    <div style="width:100%;margin-top:16px;">
      <button-component
        :onClick="Cancel"
        buttonName="Cancel"
        buttonVariation="secondary"
      >
      </button-component>
      <button-component
        :onClick="saveEditedData"
        buttonName="Save"
        buttonVariation="primary"
        style="float: right;"
      >
      </button-component> 
    </div>
    </mds-row>
    </mds-layout-grid>
    <div v-if="showLoader">
      <loader-component loaderSize="large"></loader-component>
    </div>
    <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :dismissDelay="5000"
      @close="showNotification = false"
    ></notification-component>

    <!-- modal -->
    <div class="add-data-points-modal">
      <add-data-points-modal
        v-if="modalOpen"
        @onCancelClick="Cancel"
        @modalAction="modalAction"
        @templateAddedToGrid="getDatapointValuesFromTemplate"
        @datapointsAddedToGrid="getDatapointValues"
        @groupDatapointAddedToGrid="getGroupDatapointvalues"
        :showTemplatesTab="showTemplates"
        :showGroupDatapointsTab="showGroupDatapoints"
        :showIndividualDatapointsTab="showIndividualDatapoints"
      ></add-data-points-modal>
    </div>
    <dialog-component
      ref="confirmDialogue"
      :iconDetails="dialogIcon"
      :dialogMessage="dialogMessage"
    ></dialog-component>
    <mds-dialog
    v-if="!showLoader"
      v-model="toggle"
      width="500px"
      action-required
      title="Save as a template?"
    >
      Saving a template makes it easier to import this same type of data again
      in the future.
      <template v-slot:mds-dialog-supplemental-content>
        <mds-form>
          <mds-input
            label="Template Name"
            v-model.trim.lazy="templateFileName"
            :error="saveTemplateError"
            :error-text="saveTemplateErrorText"
          ></mds-input>
           <mds-checkbox v-model="overWriteExisting">Overwrite current template</mds-checkbox>
        </mds-form>
      </template>
      <template v-slot:mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <button-component
            :onClick="closeSaveTemplate"
            buttonName="Cancel"
            buttonVariation="secondary"
          >
          </button-component>
          <button-component
            :onClick="onSaveTemplateClicked"
            buttonName="Save Template"
            buttonVariation="primary"
            :isDisabled="templateFileName.length == 0"
          >
          </button-component>
          <!-- <mds-button type="button" variation="secondary" @click="toggle=!toggle"> Cancel </mds-button>
                  <mds-button type="button" variation="primary" @click="toggle=!toggle"> Save </mds-button> -->
        </mds-button-container>
      </template>
    </mds-dialog>
  </div>
</template>
<script>
import { MdsLayoutGrid, MdsRow } from '@mds/layout-grid'
import { MdsButtonContainer } from "@mds/button";
import ButtonComponent from "../ui_component/ButtonComponent.vue";
import Enums from "../../config/Enums.json";
import { mapGetters, mapActions, mapMutations } from "vuex";
import BulkEditTableComponent from "../common_components/BulkEditTableComponent.vue";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import AddDataPointsModal from "../common_components/AddDataPointsModal.vue";
import {
  groupBy,
  BulkEditImport,
  BulkEditSaveTemplate,
  BulkEditDownloadTemplate,
} from "../../services/bulk_edit_service.js";
import DialogComponent from "../ui_component/DialogComponent.vue";
import MdsDialog from "@mds/dialog";
import MdsInput from "@mds/input";
import MdsCheckbox from '@mds/checkbox'
import MdsForm from "@mds/form";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import { dateFormatterMixin } from "../../mixins/date.js";
// import { MdsDataTable } from '@mds/data-table'
export default {
  components: {
    MdsLayoutGrid, MdsRow,
    ButtonComponent,
    BulkEditTableComponent,
    LoaderComponent,
    // MdsDataTable
    AddDataPointsModal,
    DialogComponent,
    MdsDialog,
    MdsInput,
    MdsForm,
    MdsButtonContainer,
    NotificationComponent,
    MdsCheckbox,

  },

  mixins: [dateFormatterMixin],

  data() {
    return {
      
      referencedentitytypeids:[],
      enumIds:[],
      overWriteExisting:false,
      saveTemplateError:false,
      saveTemplateErrorText:[],
      addDataPointsClick:0,
      templateFileName: "",
      toggle: false,
      // add-data-points-modal-data-properties
      isValidationError: false,
      dialogIcon: "",
      dialogMessage: "",
      showTemplates: true,
      showGroupDatapoints: true,
      showIndividualDatapoints: true,
      modalOpen: true,
      datapointValuesPayload: {
        languageId: "en",
        countryId: null,
        entityIds: [],
        datapointIds: [],
        groupIds: [],
        entityTypeId: 0,
        templateId: 0,
      },
      importPayload: {
        header: [],
        data: [],
        fileType: 2,
        fileName: "string",
        entityTypeId: 1,
      },
      showImportModalPopup: false,
      reviewActionStatus: false,
      uploadedFileName: "",
      uploadedData: [],
      notificationMessage: "",
      showNotification: false,
      notificationType: "",
      notificationKey: "",
      uploadedFile: [],
      showLoader: false,
      tableComponentKey: 0,
      entityTypeList: [],
      dataTypeIds: Enums.dataTypes,
      selectEnumKeySec: 0,
      multiSelectRefKey: 0,
      disableAddDataPoints: false,
      rows: [],
      tableData: [],
      tableHeader: [],
    };
  },
  computed: {
    ...mapGetters("entity", ["getEntityTypes"]),
    ...mapGetters("bulkEdit", [
      "getAddedToGridDatapoints",
      "getSelectedDatapoints",
      "getSelectedGroupDatapointId",
      "getSelectedEntities",
      "getSelectedEntityType",
      "getDatapointsValuesForGrid",
      "getTemplatesList",
      "getSelectedTemplateId"
    ]),
...mapGetters("dropDowns", ["getEnumDropDowns","getReferenceDropDowns"]),
    
  },
  async mounted() {
    this.showLoader = true;
    if (this.getEntityTypes.length == 0) {
      await this.fetchEntityTypes();
      this.entityTypeList = this.getEntityTypes;
    } else {
      this.entityTypeList = this.getEntityTypes;
    }
    this.bindEventHandlers()
    // this.tableComponentKey+=1
    this.showLoader = false;
  },
  destroyed() {
    this.removeHanlder();
  },
  methods: {
     ...mapActions("dropDowns", ["fetchEnumDropDownDetails","fetchReferenceDropDownDetails"]),
    ...mapActions("entity", ["fetchEntityTypes"]),
    ...mapActions("bulkEdit", [
      "fetchEntityTypes",
      "fetchDatapointsValuesForGrid",
    ]),
    ...mapMutations("bulkEdit", [
      "setAddedToGridDatapoints",
      "setAddedToGridGroupDatapointId",
    ]),
    removeHanlder(){
       window.removeEventListener("click", this.windowClick);
    },
    bindEventHandlers() {
      window.addEventListener("click", this.windowClick, true);
    },
    windowClick(e){
      if(e !=undefined){
        if(e.target==undefined || e.target.className!="mds-list-group__item-text___VueMDS3Demo"){
        for(let i=0;i<this.tableData.length;i++){
        this.tableData[i].showErrorToolTip=false
      }
      }
      }else{
         for(let i=0;i<this.tableData.length;i++){
        this.tableData[i].showErrorToolTip=false
      }
      }
      
      
    },
    onBreadCrumsClick(path){
      console.log(path);
      if(path == "entities"){
        this.$emit("onCloseBulkEditGridTable")
      }else if(path == "home"){
        this.$router.push("/home").catch(() => {});
      }
    },
    Cancel(){
      // this.$router.push("/entity-list").catch(() => {});
      this.$emit("onCloseBulkEditGridTable")
    },
    showSaveDialog() {
      this.toggle = true;
      this.templateFileName=""
      this.saveTemplateError=false
      this.saveTemplateErrorText=[]
    },
    closeSaveTemplate(){
      this.toggle = false;
    },
    async downloadTemplate(){
      this.showLoader=true
      await this.convertPayloadJson(false,true);
      this.importPayload.fileName = "downloadFile";
      this.importPayload.entityTypeId =this.getSelectedEntityType
      if(this.showGroupDatapoints){
        this.importPayload.fileType = 3
      }else{
        this.importPayload.fileType = 2
      }
      var response = await BulkEditDownloadTemplate(this.importPayload);
      if(response.isError){
         this.notificationMessage = response.data.message;
        this.notificationType = "error";
        this.notificationKey = "error-default";
        this.showNotification = true;
      }
      this.showLoader=false
    },
    async onSaveTemplateClicked() {
      this.showLoader = true;
      await this.convertPayloadJson(false,false);
      if(this.showGroupDatapoints){
        this.importPayload.fileType = 3
      }else{
        this.importPayload.fileType = 2
      }
      this.importPayload.fileName = this.templateFileName;
      this.importPayload.entityTypeId= this.getSelectedEntityType
      var params={
        overWriteIfExists:this.overWriteExisting
      }
      var response = await BulkEditSaveTemplate(this.importPayload,params);
      if (!response.isError && (response.status != 200)) {
        this.toggle = false;
        this.notificationMessage = response.data.message;
        this.notificationType = "success";
        this.notificationKey = "success-default";
        this.showNotification = true;
      } else if (response.status == 200) {
        this.saveTemplateError=true
        this.saveTemplateErrorText=[]
        this.saveTemplateErrorText.push(response.data.message)
      }else if (response.status == 400){
         this.saveTemplateError=true
        this.saveTemplateErrorText=[]
        this.saveTemplateErrorText.push(response.data.message)
      } else {
        this.toggle = false;
        this.notificationMessage = "Something went wrong";
        this.notificationType = "error";
        this.notificationKey = "error-default";
        this.showNotification = true;
      }
      this.showLoader = false;
      this.templateFileName = "";
    },
    async saveEditedData() {
      await this.convertPayloadJson(true,true);
      this.importData();
    },
    convertPayloadJson(addInclude,isEntityIdAdded) {
      var headers = [];
      var dataDetails = [];
      var isGroup=false
      // headers.push("rowid")
      for (var i = 0; i < this.tableHeader.length; i++) {
        if (i != 1) {
          if(this.tableHeader[i].datapointGroup == 0){
            if(!this.tableHeader[i].targetdatapoint || !addInclude){
               headers.push(this.tableHeader[i].name);
            }
          }else{
            for(var th=0;th<this.tableHeader[i].datapointGroup.length;th++){
              if(!this.tableHeader[i].datapointGroup[th].targetdatapoint || !addInclude){
                 headers.push(this.tableHeader[i].datapointGroup[th].name)

              }
            }
          }
        }
      }
      if(isEntityIdAdded){
          headers.splice(1, 0, "Entity Id");
          }
      if(addInclude){
        headers.push("Include")
        if(this.showGroupDatapoints){
         headers.push("Action")
          headers.push("Arrayindex")
      }
      }
      
      for (var j = 0; j < this.tableData.length; j++) {
        var data = [];
        // data.push(j)
        data.push(this.tableData[j].entityName);
         if(isEntityIdAdded){
        data.push(this.tableData[j].entityId);
         }
        // data.push(this.tableData[j].type)
        for (var k = 0; k < this.tableData[j].datapointdetails.length; k++) {
          if(this.tableData[j].datapointdetails[k].datapointgroup.length == 0){
               if(!this.tableData[j].datapointdetails[k].targetdatapoint || !addInclude){
                 if(!this.tableData[j].datapointdetails[k].isna){
                  if(this.tableData[j].datapointdetails[k].enumid != null || this.tableData[j].datapointdetails[k].datatypeenum == this.dataTypeIds.IdInteger){
                    if(this.tableData[j].datapointdetails[k].arraylength == 1){
                        data.push(this.tableData[j].datapointdetails[k].datapointText)
                      }else{
                        if(this.tableData[j].datapointdetails[k].datapointText == null){
                          data.push('')
                        }else{
                          data.push(this.tableData[j].datapointdetails[k].datapointText)
                        }
                      }

                  }else if(this.tableData[j].datapointdetails[k].datatypeid == this.dataTypeIds.Date){
                    if(this.tableData[j].datapointdetails[k].datapointvalue ){
                    // converting date to DD-MM-YYYY format
                    data.push(this.convertAdditionalSearchDate(this.tableData[j].datapointdetails[k].datapointvalue))

                    }else{
                      if(addInclude){
                        data.push(null)
                      }else{
                        
                      data.push('')
                      }
                    }
                  }else{
                    if(this.tableData[j].datapointdetails[k].datapointvalue != null && this.tableData[j].datapointdetails[k].datapointvalue.constructor === Array){
                    data.push(this.tableData[j].datapointdetails[k].datapointvalue.join(";"))
                  }else{
                    if(this.tableData[j].datapointdetails[k].datapointvalue == null){
                      data.push('')
                      }else{
                      data.push(this.tableData[j].datapointdetails[k].datapointvalue)

                      }
                  // data.push(this.tableData[j].datapointdetails[k].datapointvalue);

                  }
                    
                  }
                }else{
                  data.push(Enums.NotApplicableText)
                } 
             
            }
           
          }else{
            isGroup=true
            for(var td=0;td<this.tableData[j].datapointdetails[k].datapointgroup.length;td++){
              data = [];
              // data.push(j)
              data.push(this.tableData[j].entityName);
              data.push(this.tableData[j].entityId);
              for(var tdList=0;tdList<this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist.length;tdList++){
                   if(!this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].targetdatapoint || !addInclude){
                     if(!this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].isna){
                      if(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].enumid != null || this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datatypeenum == this.dataTypeIds.IdInteger){
                        if(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].arraylength == 1){
                            data.push(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointText)
                          }else{
                            if(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointText == null){
                              data.push('')
                            }else{
                              data.push(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointText)
                            }
                          }
                      }else if(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datatypeid == this.dataTypeIds.Date ||this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE' ||this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointname.toString().toUpperCase() == 'KEY DATE'){
                        if(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointvalue){
                          
                          // converting date to DD-MM-YYYY format
                          data.push(this.convertAdditionalSearchDate(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointvalue))
                        }else{
                          if(addInclude){
                            data.push(null)
                          }else{
                            
                          data.push('')
                          }
                        }
                  }else{
                        if(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointvalue != null && this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointvalue.constructor === Array){
                          data.push(this.tableData[j].datapointdetails[k].datapointvalue.join(";"))
                        }else{
                          if(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointvalue == null){
                            data.push('')
                          }else{
                            data.push(this.tableData[j].datapointdetails[k].datapointgroup[td].datapointlist[tdList].datapointvalue)

                          }
                        }
                        
                      }
                      
                    }else{
                      data.push(Enums.NotApplicableText)
                    }
                }
               
              }
              if(addInclude){
                    data.push(1)
                     data.push(this.tableData[j].datapointdetails[k].datapointgroup[td].action)
                 data.push(this.tableData[j].datapointdetails[k].datapointgroup[td].arrayindex)
                }
                
                
                 dataDetails.push(data);
            }
          }
        }
        if(!isGroup){
         if(addInclude){
            data.push(1)
          }
          dataDetails.push(data);

        }
      }
      this.importPayload.header = headers;
      this.importPayload.data = dataDetails;
        this.importPayload.entityTypeId =this.getSelectedEntityType
      if(this.showGroupDatapoints){
        this.importPayload.fileType = 3
      }else{
        this.importPayload.fileType = 2
      }
    },
    async importData() {
      this.showLoader = true;
      var response = await BulkEditImport(this.importPayload);
      
      if (response.status == 200) {
        this.showLoader = false;
         this.$emit("onCloseBulkEditGridTableWithMessage",response.data)
      } else if(response.status == 400) {
        //  create the validation error
        this.isValidationError = true;
        if(!this.showGroupDatapoints){
           for (var c = 0; c < this.tableData.length; c++) {
                  this.tableData[c].isError = false;
                  this.tableData[c].messages=[]
                  for (var ck = 0;ck < this.tableData[c].datapointdetails.length;ck++) {
                    this.tableData[c].datapointdetails[ck].isError = false;
                  }
              }
              for (var i = 0; i < response.data.validation.length; i++) {
              for (var rj = 0; rj < this.tableData.length; rj++) {
                if (response.data.validation[i].rowid == rj) {
                  this.tableData[rj].isError = true;
                  this.tableData[rj].messages.push(response.data.validation[i].msg);
                }
              }
              // if(response.data.validation[i].colid !=0 && response.data.validation[i].colid !=1){
              //   for (var j = 0; j < this.tableData.length; j++) {
              //   if (response.data.validation[i].rowid == j) {
                
              //     for (var k = 0;k < this.tableData[j].datapointdetails.length;k++) {
              //       if (response.data.validation[i].colid == k+2) {

              //         this.tableData[j].datapointdetails[k].isError = true;
              //       }

              //       }
              //     }
                
              // }
              // }
            
            }
            let tableWithErrorList =  await this.setTargetDatapointErrors(response.data.validation)
            for(let i=0;i<tableWithErrorList.length;i++){
              for(let i2=0;i2<this.tableData.length;i2++){
                if(tableWithErrorList[i].entityId == this.tableData[i2].entityId){
                  for(let j=0;j<tableWithErrorList[i].datapointdetails.length;j++){
                    for(let j2=0;j2<this.tableData[i2].datapointdetails.length;j2++){
                      if(tableWithErrorList[i].datapointdetails[j].datapointid == this.tableData[i2].datapointdetails[j2].datapointid 
                      && tableWithErrorList[i].datapointdetails[j].arrayindex == this.tableData[i2].datapointdetails[j2].arrayindex){
                        this.tableData[i2].datapointdetails[j2].isError = tableWithErrorList[i].datapointdetails[j].isError
                      }
                    }
                  }
                }
              }
            }

        }else{
          let groupByEntities=''
        groupByEntities=this.getUniqueEntites(response.data.validation)
        let validationDetails=[]
        for(let i1=0;i1<groupByEntities.length;i1++){
          var validationObj={
            "entityid":groupByEntities[i1],
            "validations":[]
          }
          for(let j1=0;j1<response.data.validation.length;j1++){
            if(groupByEntities[i1] == response.data.validation[j1].entityid){
              validationObj.validations.push(response.data.validation[j1])
            }
          }
          validationDetails.push(validationObj)
        }
          for (var d = 0; d < this.tableData.length; d++) {
            this.tableData[d].isError = false;
            this.tableData[d].messages=[]
            for (var dk = 0;dk < this.tableData[d].datapointdetails.length;dk++) {
              for(var dkg=0;dkg<this.tableData[d].datapointdetails[dk].datapointgroup[0].datapointlist.length;dkg++){
                this.tableData[d].datapointdetails[dk].datapointgroup[0].datapointlist[dkg].isError = false;
              }
            }
          }
          
          let datapointGrouplist=[]
          for(let v=0;v<this.tableData.length;v++){
            // datapointdetails
            for(let vi=0;vi<this.tableData[v].datapointdetails[0].datapointgroup.length;vi++){
              datapointGrouplist.push(this.tableData[v].datapointdetails[0].datapointgroup[vi])
            }
          }
          // clearing validations
          for(let c=0;c<datapointGrouplist.length;c++){
            for(let cd=0;cd<datapointGrouplist[c].datapointlist.length;cd++){
              datapointGrouplist[c].datapointlist[cd].isError = false;
            }
          }
        let removedTargetDatapointsGroupList = await this.setTargetDatapointErrorsForGroups(datapointGrouplist,response.data.validation)
        for(let r=0;r<removedTargetDatapointsGroupList.length;r++){
          for(let dgl=0;dgl<datapointGrouplist.length;dgl++){
            if(r == dgl){
              for(let r1 =0;r1<removedTargetDatapointsGroupList[r].datapointlist.length;r1++){
                 for(let dgld=0;dgld<datapointGrouplist[dgl].datapointlist.length;dgld++){
                   if(removedTargetDatapointsGroupList[r].datapointlist[r1].datapointid == datapointGrouplist[dgl].datapointlist[dgld].datapointid){
                     datapointGrouplist[dgl].datapointlist[dgld].isError=removedTargetDatapointsGroupList[r].datapointlist[r1].isError
                   }
                 }
              }
            }
          }
        }
          // for(let dgl=0;dgl<datapointGrouplist.length;dgl++){
          //   for(let vl=0;vl<response.data.validation.length;vl++){
          //     if(dgl ==response.data.validation[vl].rowid){
          //       for(let dgld=0;dgld<datapointGrouplist[dgl].datapointlist.length;dgld++){
          //         if(response.data.validation[vl].colid == dgld+2){
          //           datapointGrouplist[dgl].datapointlist[dgld].isError = true;
          //         }
          //       }
          //     }
          //   }
          // }
           for (var gd = 0; gd < validationDetails.length; gd++) {
              for (var grj = 0; grj < this.tableData.length; grj++) {
                if (validationDetails[gd].entityid == this.tableData[grj].entityId) {
                  this.tableData[grj].isError = true;
                  for(let vm=0;vm<validationDetails[gd].validations.length;vm++){
                    this.tableData[grj].messages.push(validationDetails[gd].validations[vm].msg);
                  }
                  // 
                  // for(let gdv=0;gdv<validationDetails[gd].validations.length;gdv++){
                  //   if(validationDetails[gd].validations[gdv].colid !=0 && validationDetails[gd].validations[gdv].colid !=1){
                  //     for (var gdk = 0;gdk < this.tableData[grj].datapointdetails.length;gdk++) {
                  //       for(let dg=0;dg<this.tableData[grj].datapointdetails[gdk].datapointgroup.length;dg++){
                  //         if(validationDetails[gd].validations[gdv].rowid == dg){
                  //            for(var group=0;group<this.tableData[grj].datapointdetails[gdk].datapointgroup[dg].datapointlist.length;group++){
                  //          if (validationDetails[gd].validations[gdv].colid == group+2) {
                  //          this.tableData[grj].datapointdetails[gdk].datapointgroup[dg].datapointlist[group].isError = true;
                  //         }
                  //        }
                  //         }
                  //       }
                  //       //  for(var group=0;group<this.tableData[grj].datapointdetails[gdk].datapointgroup[0].datapointlist.length;group++){
                  //       //    if (validationDetails[gd].validations[gdv].colid == group+2) {
                  //       //    this.tableData[grj].datapointdetails[gdk].datapointgroup[0].datapointlist[group].isError = true;
                  //       //   }
                  //       //  }
                  //     }
                  //   }
                  // }
                }
              }
              // if(response.data.validation[gd].colid !=0 && response.data.validation[gd].colid !=1){
              //    for (var gdj = 0; gdj < this.tableData.length; gdj++) {
              //      if (response.data.validation[gd].rowid == gdj) {
              //         for (var gdk = 0;gdk < this.tableData[gdj].datapointdetails.length;gdk++) {
              //           for(var group=0;group<this.tableData[gdj].datapointdetails[gdk].datapointgroup[0].datapointlist.length;group++){
              //             if (response.data.validation[gd].colid == gdk+2) {
              //              this.tableData[gdj].datapointdetails[gdk].datapointgroup[0].datapointlist[group].isError = true;
              //             }
              //           }
              //         }
              //      }
              //    }
              // }

           }
           

        }
      this.showLoader = false;
      }
    },
   

    setTargetDatapointErrorsForGroups(datapointGrouplistDetails,validation){
      let datapointGrouplist = JSON.parse(JSON.stringify(datapointGrouplistDetails));
      for(let dgl=0;dgl<datapointGrouplist.length;dgl++){
        let dgld=datapointGrouplist[dgl].datapointlist.length
            while(dgld--){
              if(datapointGrouplist[dgl].datapointlist[dgld].targetdatapoint == true){
                        datapointGrouplist[dgl].datapointlist.splice(dgld,1)
                      }
            }
                  
          }
          for(let dgl=0;dgl<datapointGrouplist.length;dgl++){
            for(let vl=0;vl< validation.length;vl++){
              if(dgl == validation[vl].rowid){
                for(let dgld=0;dgld<datapointGrouplist[dgl].datapointlist.length;dgld++){
                  if( validation[vl].colid == dgld+2){
                    datapointGrouplist[dgl].datapointlist[dgld].isError = true;
                  }
                }
              }
            }
          }
          return datapointGrouplist
    },
    setTargetDatapointErrors(validation){
      let dublicableTable = JSON.parse(JSON.stringify(this.tableData));
      for(let i=0;i<dublicableTable.length;i++){
        let j=dublicableTable[i].datapointdetails.length
        while(j--){
          if(dublicableTable[i].datapointdetails[j].targetdatapoint == true){
            dublicableTable[i].datapointdetails.splice(j,1)
          }
        }
      }
     
       for (let i = 0; i < validation.length; i++) {
              if(validation[i].colid !=0 && validation[i].colid !=1){
                for (let j = 0; j < dublicableTable.length; j++) {
                if (validation[i].rowid == j) {
                
                  for (let k = 0;k < dublicableTable[j].datapointdetails.length;k++) {
                    if (validation[i].colid == k+2) {

                      dublicableTable[j].datapointdetails[k].isError = true;
                    }

                    }
                  }
                
              }
              }
            
            }
            return dublicableTable
    },
    getUniqueEntites(arr) {
var flags = [], output = [], l = arr.length, i;
for( i=0; i<l; i++) {
    if( flags[arr[i].entityid]) continue;
    flags[arr[i].entityid] = true;
    output.push(arr[i].entityid);
}
return output
},
    getEntityType(entityTypeId) {
      // this.entityTypeList
      var filterEntity = this.entityTypeList.filter(
        (entityType) => entityTypeId == entityType.entitytypeid
      );
      return filterEntity.entitytypedescription;
    },
    convertData() {
      for (var i = 0; i < this.rows.length; i++) {
        // obj.datapointdetails=[]
        if (this.tableData.length == 0) {
          var obj = {
            entityName: this.rows[i].entityname,
            entityId: this.rows[i].entityid,
            type: this.rows[i].entitytypeid,
            isError: false,
            showRow:true,
            showRemoveButton:false,
            datapointdetails: [],
            showErrorToolTip: false,
            messages: [],

          };
          var sameentity = this.rows.filter(
            (row) => this.rows[i].entityid == row.entityid
          );
          sameentity = sameentity.sort((a, b) => a.datapointid - b.datapointid);
          obj.datapointdetails = sameentity;
          for (var a = 0; a < obj.datapointdetails.length; a++) {
            if(obj.datapointdetails[a].datatypeid == this.dataTypeIds.Date){
                    if(obj.datapointdetails[a].datapointvalue != null && obj.datapointdetails[a].datapointvalue.length > 0 && obj.datapointdetails[a].datapointvalue != "Not Applicable"){
                      obj.datapointdetails[a].datapointvalue = dateFormatterMixin.methods.convertReportingParameterDate(obj.datapointdetails[a].datapointvalue)
                      
                    }else{
                      obj.datapointdetails[a].datapointvalue = null
                    }
            }
            obj.datapointdetails[a]["isError"] = false;
            obj.datapointdetails[a]["showRow"] = true;
            obj.datapointdetails[a]["showRemoveButton"]=false
          }
          this.tableData.push(obj);
        } else {
          //
          for (var j = 0; j < this.tableData.length; j++) {
            if (this.rows[i].entityid == this.tableData[j].entityId) {
              var checkDatapoints = this.rows.filter(
                  (row) => this.rows[i].entityid == row.entityid
                );
                for(var cd=0;cd<checkDatapoints.length;cd++){
                  for(var g=0;g<this.tableData[j].datapointdetails.length;g++){
                    if(checkDatapoints[cd].datapointid == this.tableData[j].datapointdetails[g].datapointid){
                      break
                    }else{
                      if(g == this.tableData[j].datapointdetails.length-1){
                        this.tableData[j].datapointdetails.push(checkDatapoints[cd])
                      }
                    }
                  }

                }
                 this.tableData[j].datapointdetails =  this.tableData[j].datapointdetails.sort(
                  (a, b) => a.datapointid - b.datapointid
                );
              break;
            } else {
              if (j == this.tableData.length - 1) {
                var obj2 = {
                  entityName: this.rows[i].entityname,
                  entityId: this.rows[i].entityid,
                  type: this.rows[i].entitytypeid,
                  isError: false,
                  showRow:true,
                  showRemoveButton:false,
                  datapointdetails: [],
                  showErrorToolTip: false,
                  messages: [],
                };
                var sameentity2 = this.rows.filter(
                  (row2) => this.rows[i].entityid == row2.entityid
                );
                sameentity2 = sameentity2.sort(
                  (a, b) => a.datapointid - b.datapointid
                );
                obj2.datapointdetails = sameentity2;
                for (var a2 = 0; a2 < obj2.datapointdetails.length; a2++) {
                  if(obj2.datapointdetails[a2].datatypeid == this.dataTypeIds.Date){
                    if(obj2.datapointdetails[a2].datapointvalue != null && obj2.datapointdetails[a2].datapointvalue.length > 0 && obj2.datapointdetails[a2].datapointvalue != "Not Applicable"){
                     obj2.datapointdetails[a2].datapointvalue = dateFormatterMixin.methods.convertReportingParameterDate(obj2.datapointdetails[a2].datapointvalue);
                    }else{
                      obj2.datapointdetails[a2].datapointvalue = null
                    }
                  }
                  obj2.datapointdetails[a2]["isError"] = false;
                  obj2.datapointdetails[a2]["showRow"]=true
                  obj2.datapointdetails[a2]["showRemoveButton"]=false
                }
                 obj2.datapointdetails.sort((a, b) => a.sortorder - b.sortorder);
                this.tableData.push(obj2);
              }
            }
          }
        }
      }
    },

    convertHeaderJson() {
      for (var i = 0; i < this.rows.length; i++) {
        if (this.tableHeader.length == 0) {
          var headerObject = {
            datapointid: this.rows[i].datapointid,
            name: this.rows[i].datapointname,
            datapointGroup: [],
            sortorder:this.rows[i].sortorder
          };
          if (this.rows[i].datapointgroup.length > 0) {
             headerObject.name= this.rows[i].groupname
            for (
              var g = 0;
              g < this.rows[i].datapointgroup[0].datapointlist.length;
              g++
            ) {
              var groupObj = {
                datapointid: this.rows[i].datapointgroup[0].datapointlist[g]
                  .datapointid,
                name: this.rows[i].datapointgroup[0].datapointlist[g]
                  .datapointname,
                sourcedatapoint:this.rows[i].datapointgroup[0].datapointlist[g]
                        .sourcedatapoint,
                targetdatapoint:this.rows[i].datapointgroup[0].datapointlist[g]
                .targetdatapoint,
                sortorder:this.rows[i].datapointgroup[0].datapointlist[g].sortorder
              };
              headerObject.datapointGroup.push(groupObj);
            }
          }else{
            headerObject.sourcedatapoint=this.rows[i].sourcedatapoint
            headerObject.targetdatapoint=this.rows[i].targetdatapoint
          }
          this.tableHeader.push(headerObject);
        } else {
          for (var j = 0; j < this.tableHeader.length; j++) {
            if (this.tableHeader[j].datapointid === this.rows[i].datapointid) {
              break;
            } else {
              if (j == this.tableHeader.length - 1) {
                var headerObj2 = {
                  datapointid: this.rows[i].datapointid,
                  name: this.rows[i].datapointname,
                  datapointGroup: [],
                  sortorder:this.rows[i].sortorder
                };
                if (this.rows[i].datapointgroup.length > 0) {
                 headerObj2.name= this.rows[i].groupname
                  for ( var d = 0; d < this.rows[i].datapointgroup[0].datapointlist.length; d++) {
                    var groupObj2 = {
                      datapointid: this.rows[i].datapointgroup[0]
                        .datapointlist[d].datapointid,
                      name: this.rows[i].datapointgroup[0].datapointlist[d]
                        .datapointname,
                        sourcedatapoint:this.rows[i].datapointgroup[0].datapointlist[d]
                        .sourcedatapoint,
                        targetdatapoint:this.rows[i].datapointgroup[0].datapointlist[d]
                        .targetdatapoint,
                        sortorder:this.rows[i].datapointgroup[0].datapointlist[d].sortorder
                    };
                    headerObj2.datapointGroup.push(groupObj2);
                  }
                }else{
                    headerObj2.sourcedatapoint=this.rows[i].sourcedatapoint
                    headerObj2.targetdatapoint=this.rows[i].targetdatapoint
                  }
                this.tableHeader.push(headerObj2);
              }
            }
          }
        }
      }
      if(this.addDataPointsClick > 0 && !this.showGroupDatapoints){
         this.tableHeader.splice(0, 2);
      }
      this.tableHeader = this.tableHeader
        .slice()
        .sort((a, b) => a.datapointid - b.datapointid);
      var entityObj = {
        datapointid: null,
        name: "Entity Name",
        datapointGroup: [],
        sourcedatapoint: false,
        targetdatapoint: false,
        sortorder:0,
      };
      var TypeObj = {
        datapointid: null,
        name: "Type",
        datapointGroup: [],
        sourcedatapoint: false,
        targetdatapoint: false,
        sortorder:0
      };
      this.tableHeader.sort((a, b) => a.sortorder - b.sortorder);
      this.tableHeader.splice(0, 0, entityObj);
      this.tableHeader.splice(1, 0, TypeObj);
    },
    
   
    


    // methods for add-data-points modal
    addDatapoints() {
      this.addDataPointsClick+=1
      this.modalOpen = true;
    },

    modalAction(value) {
      this.modalOpen = value;
    },

    getDatapointValuesFromTemplate(template, datapoints) {
      // need to add condition for templatetypeid
      if(template.templatetypeid === 2) {
        this.showTemplates = false;
        this.showIndividualDatapoints = true;
        this.showGroupDatapoints = false;
        this.getDatapointValues(datapoints);
      } else if(template.templatetypeid === 3) {
        this.disableAddDataPoints = true;
      }
    },

    async getDatapointValues(datapoints) {
      let datapointsSendToServer=[]
      if(this.getAddedToGridDatapoints.length > 0){
       let unCheckedValues = this.getAddedToGridDatapoints.filter(el => {return !datapoints.find(obj => {return el.id === obj.id;})})
       let newCheckedValues = datapoints.filter(el => {return !this.getAddedToGridDatapoints.find(obj => {return el.id === obj.id;})})
        if(unCheckedValues.length > 0){
            this.showLoader = true;
           for(let i=0;i<this.tableData.length;i++){
             for(let k=0;k<unCheckedValues.length;k++){
                let j=this.tableData[i].datapointdetails.length
               while(j--){
               if(this.tableData[i].datapointdetails[j].datapointid == unCheckedValues[k].id){
                
                 this.tableData[i].datapointdetails.splice(j,1)
                
               }
             }
             }
           }
           for(let m=0;m<unCheckedValues.length;m++){
           let l = this.tableHeader.length
             while(l--){
               if(this.tableHeader[l].datapointid != null){
                 if(this.tableHeader[l].datapointid == unCheckedValues[m].id){
                   this.tableHeader.splice(l,1)
                  }
               }
               
             }
           }
           for(let i=0;i<this.tableData.length;i++){
              for(let j=0;j<this.tableData[i].datapointdetails.length;j++){
                if(this.tableData[i].datapointdetails[j].datapointvalue != null && this.tableData[i].datapointdetails[j].datapointvalue.constructor === Array){
                  this.tableData[i].datapointdetails[j].values=this.tableData[i].datapointdetails[j].datapointvalue
                }
              }
            }
             this.setAddedToGridDatapoints(datapoints);
             if(newCheckedValues.length == 0){
                setTimeout(() => this.showLoader = false, 1000);
             }
              
         }
        if(newCheckedValues.length > 0){
          datapointsSendToServer=newCheckedValues
          for(let i=0;i<this.tableData.length;i++){
            for(let j=0;j<this.tableData[i].datapointdetails.length;j++){
              if(this.tableData[i].datapointdetails[j].datapointvalue != null && this.tableData[i].datapointdetails[j].datapointvalue.constructor === Array){
                this.tableData[i].datapointdetails[j].values=this.tableData[i].datapointdetails[j].datapointvalue
              }
            }
          }
          
          this.settingUpFetchDatapoints(datapointsSendToServer,datapoints)
        }
      }else{
        datapointsSendToServer=datapoints
        this.settingUpFetchDatapoints(datapointsSendToServer,datapoints)
      }
      
    },
    async settingUpFetchDatapoints(datapointsSendToServer,orginalDatapoints){
      this.setAddedToGridDatapoints(orginalDatapoints);
      this.showTemplates = false;
      this.showGroupDatapoints = false;
      this.showLoader = true;
      await this.convertDatapointValuesPayload(datapointsSendToServer);
      await this.fetchDatapointValues()
      this.showLoader = false;
    },
   async getGroupDatapointvalues(groupDatapoint) { 
     this.showLoader=true
      this.setAddedToGridGroupDatapointId(groupDatapoint.id);
      this.showTemplates = false;
      this.showIndividualDatapoints = false;
       await this.groupDatapointPayload(groupDatapoint);
        // await this.fetchDatapointValues()
        this.showLoader=false 
    },
  async  fetchDatapointValues(){
       if (
        this.datapointValuesPayload.entityIds.length > 0 &&
        this.datapointValuesPayload.datapointIds.length > 0
      ) {
        await this.fetchDatapointsValuesForGrid(this.datapointValuesPayload);
        if (this.getDatapointsValuesForGrid.length > 0) {
          if(this.rows.length == 0){
            this.rows=[]
            this.rows = this.getDatapointsValuesForGrid;
            await this.rowConvertJson()
             await this.convertHeaderJson();
              await this.convertData();
              await this.groupDataPoints();
            }else{
              this.rows=this.getDatapointsValuesForGrid
              await this.rowConvertJson()
              for (let a2 = 0; a2 < this.rows.length; a2++) {
                  if(this.rows[a2].datatypeid == this.dataTypeIds.Date){
                    if(this.rows[a2].datapointvalue != null && this.rows[a2].datapointvalue.length > 0 && this.rows[a2].datapointvalue != "Not Applicable"){
                     console.log(this.rows[a2].datapointvalue);
                     this.rows[a2].datapointvalue = dateFormatterMixin.methods.reverseTheDate(this.rows[a2].datapointvalue);
                    }else{
                      this.rows[a2].datapointvalue = null
                    }
                  }
                  this.rows[a2]["isError"] = false;
                  this.rows[a2]["showRow"]=true
                  this.rows[a2]["showRemoveButton"]=false
                }
                let duplicableTableData=JSON.parse(JSON.stringify(this.tableData));
                for(let e=0;e<duplicableTableData.length;e++){
                  duplicableTableData[e].datapointdetails=[]
                }
                for(let rd=0;rd<this.rows.length;rd++){
                  for(let d=0;d<duplicableTableData.length;d++){
                    if(this.rows[rd].entityid == duplicableTableData[d].entityId){
                      duplicableTableData[d].datapointdetails.push(this.rows[rd])
                    }
                  }
                }
                 for (var dt = 0; dt < duplicableTableData.length; dt++) {
                    var groupFinal = await groupBy(
                      duplicableTableData[dt].datapointdetails,
                      "datapointid",
                      1
                    );
                    duplicableTableData[dt].datapointdetails = groupFinal.sort((a, b) => a.sortorder - b.sortorder);
                }
                  for(let jd=0;jd<duplicableTableData[0].datapointdetails.length;jd++){
                      let newheaderObj = {
                        datapointid: duplicableTableData[0].datapointdetails[jd].datapointid,
                        name: duplicableTableData[0].datapointdetails[jd].datapointname,
                        datapointGroup: [],
                        sortorder:duplicableTableData[0].datapointdetails[jd].sortorder,
                        sourcedatapoint:duplicableTableData[0].datapointdetails[jd].sourcedatapoint,
                        targetdatapoint:duplicableTableData[0].datapointdetails[jd].targetdatapoint
                      };
                      this.tableHeader.push(newheaderObj);
                  }

                for(let i=0;i<duplicableTableData.length;i++){
                  for(let k=0;k<this.tableData.length;k++){
                  if(duplicableTableData[i].entityId == this.tableData[k].entityId){
                    for(let l=0;l<duplicableTableData[i].datapointdetails.length;l++){
                    this.tableData[k].datapointdetails.push(duplicableTableData[i].datapointdetails[l])
                    }
                  }
                }
                }

            }
            
         
        
        }
      }
    },
    async rowConvertJson(){
      for (var i = 0; i < this.rows.length; i++) {
              this.rows[i]["datapointgroup"] = [];
              this.rows[i]["datapointText"]=""
              if(this.rows[i].enumid != null){
                this.enumIds.push(this.rows[i].enumid)
              } 
              if(this.rows[i].referencedentitytypeid != null){
                this.referencedentitytypeids.push(this.rows[i].referencedentitytypeid)
              }
              // fetchReferenceDropDownDetails
            }
            this.enumIds = [...new Set(this.enumIds)]
            this.referencedentitytypeids=[...new Set(this.referencedentitytypeids)]
            if(this.getEnumDropDowns.length == 0){
              await this.fetchEnumDropDownDetails(this.enumIds)
            }else{
              var getEnums=[]
              for(let j=0;j<this.enumIds.length;j++){
                var checkEnum = this.getEnumDropDowns.filter((enumdetails) => this.enumIds[j]== enumdetails.enumid)
                if(checkEnum.length == 0){
                   getEnums.push(this.enumIds[j])
                }
                if(j==this.enumIds.length-1){
                  if(getEnums.length !=0){
                    await this.fetchEnumDropDownDetails(getEnums)
                  }
                }
              }
              
            }
             if(this.getReferenceDropDowns.length == 0){
              await this.fetchReferenceDropDownDetails(this.referencedentitytypeids)
            }else{
              var getReferences=[]
              for(let r=0;r<this.referencedentitytypeids.length;r++){
                var checkReference = this.getReferenceDropDowns.filter((referencedetails) => this.referencedentitytypeids[r]== referencedetails.refrenceid)
                if(checkReference.length == 0){
                   getReferences.push(this.referencedentitytypeids[r])
                }
                if(r==this.referencedentitytypeids.length-1){
                  if(getReferences.length !=0){
                    await this.fetchReferenceDropDownDetails(getReferences)
                  }
                }
              }
              
            }
    },
 async getEnumValues(){
       this.enumIds = [...new Set(this.enumIds)]
            if(this.getEnumDropDowns.length == 0){
              await this.fetchEnumDropDownDetails(this.enumIds)
            }else{
              var getEnums=[]
              for(let j=0;j<this.enumIds.length;j++){
                var checkEnum = this.getEnumDropDowns.filter((enumdetails) => this.enumIds[j]== enumdetails.enumid)
                if(checkEnum.length == 0){
                   getEnums.push(this.enumIds[j])
                }
                if(j==this.enumIds.length-1){
                  if(getEnums.length !=0){
                    await this.fetchEnumDropDownDetails(getEnums)
                  }
                }
              }
              
            }
    },
     async getReferenceValues(){
         this.referencedentitytypeids=[...new Set(this.referencedentitytypeids)]
            if(this.getReferenceDropDowns.length == 0){
              await this.fetchReferenceDropDownDetails(this.referencedentitytypeids)
            }else{
              var getReferences=[]
              for(let r=0;r<this.referencedentitytypeids.length;r++){
                var checkReference = this.getReferenceDropDowns.filter((referencedetails) => this.referencedentitytypeids[r]== referencedetails.referenceid)
                if(checkReference.length == 0){
                   getReferences.push(this.referencedentitytypeids[r])
                }
                if(r==this.referencedentitytypeids.length-1){
                  if(getReferences.length !=0){
                    await this.fetchReferenceDropDownDetails(getReferences)
                  }
                }
              }
              
            }
    },
  async  convertDatapointValuesPayload(datapoints) {
      this.datapointValuesPayload.entityIds = this.getSelectedEntities;
      this.datapointValuesPayload.entityTypeId = this.getSelectedEntityType;
      this.datapointValuesPayload.datapointIds=[]
      for (var i = 0; i < datapoints.length; i++) {
        if(datapoints.datapointgroups == null){
        this.datapointValuesPayload.datapointIds.push(datapoints[i].id);
        }
      }
    },
    async groupDatapointPayload(datapoints){
      this.datapointValuesPayload.entityIds = this.getSelectedEntities;
      this.datapointValuesPayload.entityTypeId = this.getSelectedEntityType;
       this.datapointValuesPayload.groupIds=[]
      this.datapointValuesPayload.groupIds.push(datapoints.id)
       this.datapointValuesPayload.datapointIds=[]
      await this.convertGroupDatapoints(datapoints.datapointgroups)
      await this.fetchDatapointsValuesForGrid(this.datapointValuesPayload);
      this.rows=this.getDatapointsValuesForGrid
      if(this.addDataPointsClick > 0){
        this.tableHeader=[]
        this.tableData=[]
      }
      for(var e=0;e<this.rows.length;e++){
         this.rows[e].entitytypeid=this.rows[e].datapointgroups[0].entitytypeid
         this.rows[e]["datapointgroup"]=[]
        for(let je=0;je<this.rows[e].datapointgroups.length;je++){
              this.rows[e].datapointgroups[je]["datapointText"]=""
              if(this.rows[e].datapointgroups[je].enumid != null){
                        this.enumIds.push(this.rows[e].datapointgroups[je].enumid)
                      }
                      if(this.rows[e].datapointgroups[je].referencedentitytypeid != null){
                        this.referencedentitytypeids.push(this.rows[e].datapointgroups[je].referencedentitytypeid)
                      }
            }
      }
      await this.getEnumValues()
     await this.getReferenceValues()
      for(var i=0;i<this.rows.length;i++){
         this.rows[i]["datapointgroup"]=[]
        for(let j=0;j<this.rows[i].datapointgroups.length;j++){
              this.rows[i].datapointgroups[j]["datapointText"]=""
               
        }
            var arrayIndexObject = this.getUniqueArrayIndex(this.rows[i].datapointgroups)
            var groupSeperatedByArrayIndex=[]
            for(var u=0;u<arrayIndexObject.length;u++){
              var group=this.rows[i].datapointgroups.filter((group)=>arrayIndexObject[u]==group.arrayindex)
              groupSeperatedByArrayIndex.push(group)
            }
            for(let k=0;k<groupSeperatedByArrayIndex.length;k++){
               var groupDatapointListObject={
                  datapointlist:[],
                  action:"Update",
                  showRemoveButton:false,
                  arrayindex:groupSeperatedByArrayIndex[k][0].arrayindex,
                  showRow:true
                }
                groupDatapointListObject.datapointlist = groupSeperatedByArrayIndex[k]
                for(let l=0;l<groupDatapointListObject.datapointlist.length;l++){
                   if(groupDatapointListObject.datapointlist[l].datapointvalue != null){
                     break;
                   }else{
                     if(l == groupDatapointListObject.datapointlist.length-1){
                       groupDatapointListObject.action = "Add"
                     }
                   }
                }
                for(let m=0;m<groupDatapointListObject.datapointlist.length;m++){

                  if(groupDatapointListObject.datapointlist[m].datapointvalue != null){

                    
                      if(groupDatapointListObject.datapointlist[m].datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE'){
                        groupDatapointListObject.datapointlist[m].datapointvalue= dateFormatterMixin.methods.reverseTheDate(groupDatapointListObject.datapointlist[m].datapointvalue);
                      }else if(groupDatapointListObject.datapointlist[m].datapointname.toString().toUpperCase() == 'KEY DATE'){
                        groupDatapointListObject.datapointlist[m].datapointvalue= dateFormatterMixin.methods.reverseTheDate(groupDatapointListObject.datapointlist[m].datapointvalue);
                      }else if(groupDatapointListObject.datapointlist[m].datatypeid == this.dataTypeIds.Date){
                        if(groupDatapointListObject.datapointlist[m].datapointvalue != null && groupDatapointListObject.datapointlist[m].datapointvalue.length > 0 && groupDatapointListObject.datapointlist[m].datapointvalue != "Not Applicable"){
                          groupDatapointListObject.datapointlist[m].datapointvalue = dateFormatterMixin.methods.reverseTheDate(groupDatapointListObject.datapointlist[m].datapointvalue);
                          
                        }else{
                          groupDatapointListObject.datapointlist[m].datapointvalue=null
                        }
                     }

                  }
                }
                this.rows[i].datapointgroup.push(groupDatapointListObject)
            } 
      }
        await this.convertHeaderJson();
          await this.convertData();
          await this.groupDataPoints();
    },
     getUniqueArrayIndex(dataGroup) {
      return [
        ...new Set(dataGroup.map((item) =>  item.arrayindex)),
      ];
    },
   async convertGroupDatapoints(datapointgroups){
      for(var i=0;i<datapointgroups.length;i++){
        if(datapointgroups[i].datapointgroups == null){
        this.datapointValuesPayload.datapointIds.push(datapointgroups[i].id)
        }else{
           await this.convertGroupDatapoints(datapointgroups[i].datapointgroups)
        }
      }
    },
    async groupDataPoints() {
      for (var i = 0; i < this.tableData.length; i++) {
        var final = await groupBy(
          this.tableData[i].datapointdetails,
          "datapointid",
          1
        );
        this.tableData[i].datapointdetails = final.sort((a, b) => a.sortorder - b.sortorder);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.headerText{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1E1E1E;
  font-weight: 300;
  line-height: 26px;
  // font-size: 16px;
}
#bulk-edit-table-template{
      padding: 0 2%;
}
.template-header {
  @include mds-level-2-heading();
  margin-bottom: 8px;
  margin-top: 8px;
}
.vertical-line {
  box-sizing: border-box;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
  width: 1px;
  border-left: 1px dashed #cccccc;
}
.flex-buttons {
  margin-right: 16px;
  font-size: 16px;
}
.table-div {
  margin-top: 16px;
}
.data-table {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  border-collapse: collapse;
  border-spacing: 0;
  color: #1e1e1e;
}
.table-cell-header {
  border-top: 0;
  padding: 0 8px;
  position: relative;
  vertical-align: bottom;
  width: 200px;
}
.table-header-cell-inner {
  align-items: flex-end;
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
  padding-bottom: 2px;
}
.table-header-cell-text {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-weight: 600;
  color: #5e5e5e;
  margin: 0 $mds-space-half-x 0 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
.table-cell {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  border-top: solid 1px #e5e5e5;
  overflow: hidden;
  padding: 4px 8px;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: top;
}
.table-header-cell-inner-group {
  margin-bottom: 5px !important;
}
.breadCrumsDiv{
  margin-top: 16px;
  color: #5E5E5E;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}
.breadCrumsClickable{
  text-decoration: underline;
  cursor: pointer;
}
.table-header-cell-inner {
  // Ascending/descending header styles, uses before content for the sorting indicator icon
  .mds-data-table__header-cell--sorted-descending &,
  .mds-data-table__header-cell--sorted-ascending & {
    border-bottom: 1px solid $mds-text-color-primary;

    &::before {
      bottom: -1px;
      content: "";
      display: block;
      height: 21px;
      position: absolute;
      width: 19px;
    }

    .mds-data-table__header-cell-button {
      color: $mds-text-color-primary;
    }
  }

  // Descending sort icon
  .mds-data-table__header-cell--sorted-descending & {
    &::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOXB4IiBoZWlnaHQ9IjZweCIgYXJpYS1oaWRkZW49InRydWUiPjxnIGZpbGwtcnVsZT0ibm9uemVybyIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzFlMWUxZSIgZD0iTTAgMGgxMEw0Ljk5OCA1Ljl6Ii8+PHBhdGggZmlsbD0iI2FiYWJhYiIgZD0iTTkgNS45aDEwTDEzLjk5OCAweiIvPjwvZz48L3N2Zz4=");
    }
  }
}
</style>
