<template>
  <div id="add-datapoint-modal" class="add-data-points-modal-parent-container">
    <mds-modal
      title="Add Data Points"
      action-required 
      width="900px"
      v-model="toggle"
    >
      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
          <mds-button 
            class="cancel-button" 
            @click="cancel()" 
            variation="secondary"
          >Cancel</mds-button>
          <mds-button 
            class="add-to-grid-button" 
            @click="addToGrid()" 
            variation="primary" 
            :disabled="isAddToGridDisabled"
          >Add to Grid</mds-button>
        </mds-button-container>
      </template>

      <!-- tabs section (templates & individual data points) -->
      <mds-tabs>
        
           <!-- :class="selectedDatapointsList.length !== 0 || selectedGroupDatapoint !== 'None' ? 'disableTabs':''" -->
           <!-- :active="isTemplatesTabActive 
            && selectedDatapointsList.length === 0 
            && selectedGroupDatapoint === 'None' 
            && showTemplatesTab"  -->
        <mds-tabs-item
          v-if="showTemplatesTab"
          text="Templates"
          :active="isTemplatesTabActive " 
          @mds-tabs-item-active="templatesTabActive()"
        ></mds-tabs-item>
        
          <!-- :class="selectedTemplate !== 'None' || selectedGroupDatapoint !== 'None' ? 'disableTabs':''" -->
            <!-- :active="isDatapointsTabActive && selectedTemplate === 'None' && selectedGroupDatapoint === 'None'"  -->
        <mds-tabs-item
          v-if="showIndividualDatapointsTab"
          text="Individual Data Points" 
          :active="isDatapointsTabActive" 
          @mds-tabs-item-active="datapointsTabActive()"
        ></mds-tabs-item>
        
           <!-- :class="selectedTemplate !== 'None' || selectedDatapointsList.length !== 0  ? 'disableTabs':''" -->
            <!-- :active="isGroupDatapointsTabActive 
            && selectedTemplate === 'None' 
            && selectedDatapointsList.length === 0"  -->
        <mds-tabs-item 
          v-if="showGroupDatapointsTab"
          text="Group Data Points" 
          :active="isGroupDatapointsTabActive" 
          @mds-tabs-item-active="groupDatapointsTabActive()"
        ></mds-tabs-item>
      </mds-tabs>

       <!-- templates tab -->
      <div class="tab-items-container">
        <div v-if="isTemplatesTabActive && showTemplatesTab" class="templates-section-container">
          
          <div class="select-a-template-section">
            <div class="select-a-template-header-container">
              <h3 class="select-a-template-header">Select a template</h3>
            </div>

            <div class="search-container">
              <mds-search-field
                class="search-input-component" 
                v-model="searchField"
                variation="secondary"
              >
              </mds-search-field>
            </div>

            <div class="template-list">
              <loader-component 
                v-if="!datapointsResponseReceived"
              ></loader-component>
              <div class="template-list_wrapper">
                <mds-form>
                  <mds-radio-button 
                    class="template" 
                    v-for="(template, index) in searchResultsForTemplate" 
                    :key="index" 
                    :name="template.templatename" 
                    :value="template" 
                    v-model="selectedTemplate"
                  >
                    {{ template.templatename }}
                    <mds-tag v-if="template.templatetypeid == bulkoperationsFileType.GroupDataPointsPerRow">
                        Group
                    </mds-tag>
                    <!-- bulkoperationsFileType -->
                  </mds-radio-button>
                </mds-form>
              </div>

            </div>
          </div>

          <div class="selected-template-section">
            <div class="selected-template-header-container">
              <div>
                <h3 class="selected-template-header">Selected<span>:</span></h3>
              </div>
              <div v-if="selectedTemplate.templatename">{{ selectedTemplate.templatename }}</div>
              <div v-if="!selectedTemplate.templatename">None</div>
            </div>
             <div class="selected-data-points-list">
              <div 
                class="no-data-points-selected-message" 
                v-if="!selectedTemplate.templatename"
              >
                <p style="margin-top: 0;">No template selected.</p>
              </div>
            <mds-list-group v-if="selectedTemplate !== 'None'">
              <span 
                v-for="(datapoints, index) in templateHeaders"
                :key="index" class="individual-data-points-list-container" :style="index !== 0 ? 'border-top: 0.8px solid #CCCCCC' : ''">
                
              <mds-list-group-item
              
                     class="listGroup"
                :text="datapoints" 
              ></mds-list-group-item>
              </span>
            </mds-list-group>
             </div>
          </div>
        </div>

        <div v-if="isDatapointsTabActive && showIndividualDatapointsTab"  class="data-points-section-container">
          
          <div class="select-data-points-section">
            <div class="select-data-points-container">
              <h3 class="select-data-points-header">Select data points</h3>
            </div>
            <div class="search-container">
              <mds-search-field
                class="search-input-component" 
                v-model="searchField"
                variation="secondary"
              >
              </mds-search-field>
            </div>
            <div class="data-points-list">
              <loader-component
                v-if="!datapointsResponseReceived"
              ></loader-component>
              <mds-form :key="addDatapointKey" class="data-point_wrapper">
                <mds-checkbox 
                    name="individual-data-point-checkbox"
                    class="data-point" 
                    v-for="(datapoint) in searchResultsForDatapoints" 
                    :key="datapoint.id" 
                    :value="datapoint.name" 
                    :label="datapoint.name" 
                    @change="datapointSelected($event, datapoint)"
                    :checked="datapoint.isSelected"
                  ></mds-checkbox>
              </mds-form>
            </div>
          </div>

          <div class="selected-data-points-section">
            <div class="selected-data-points-header-container">
              <div>
                <h3 class="selected-data-points-header">Selected</h3>
              </div>
              <div>
                {{ "(" + selectedDatapointsList.length + ")" }}
              </div>
            </div>

            <div class="selected-data-points-list">
              <div 
                class="no-data-points-selected-message" 
                v-if="selectedDatapointsList.length === 0"
              >
                <p style="margin-top: 0;">No data points selected.</p>
              </div>
              <mds-list-group>
                <span
                  class="individual-data-points-list-container"
                  v-for="(selectedDatapoint, index) in selectedDatapointsList" 
                  :key="selectedDatapoint.id"
                  :style="index !== 0 ? 'border-top: 0.8px solid #CCCCCC' : ''"
                >
                  <span>
                    <mds-list-group-item :text="selectedDatapoint.name" icon-left="grab-handle"></mds-list-group-item>
                  </span>
                  <span style="padding: 4px 0;">
                    <mds-button
                      class="remove-icon" 
                      @click="datapointRemoved(selectedDatapoint)" 
                      variation="icon-only" 
                      icon="trash" 
                      type="button" 
                      size="small"
                    ></mds-button>
                  </span>
                </span>
              </mds-list-group>
            </div>
          </div>
        </div>

        <div v-if="isGroupDatapointsTabActive && showGroupDatapointsTab" class="group-data-points-section-container">
          
          <div class="select-a-group-data-point-section">
            <div class="select-a-group-data-point-header-container">
              <h3 class="select-a-group-data-point-header">Select a group data point</h3>
            </div>

            <div class="search-container">
              <mds-search-field
                class="search-input-component" 
                v-model="searchField"
                variation="secondary"
              >
              </mds-search-field>
            </div>

            <div class="group-data-point-list">
              <!-- <loader-component 
                v-if="!groupDatapointsResponseReceived"
              ></loader-component> -->
              <mds-form>
                <mds-radio-button 
                  class="group-data-point" 
                  v-for="(groupDatapoint, index) in searchResultsForGroupDatapoints" 
                  :key="index" 
                  :name="groupDatapoint.name" 
                  :value="groupDatapoint" 
                  :checked="groupDatapoint.isSelected"
                  v-model="selectedGroupDatapoint"
                >
                  {{ groupDatapoint.name }}
                </mds-radio-button>
              </mds-form>
            </div>
          </div>

          <div class="selected-group-data-point-section">
            <div class="selected-group-data-point-header-container">
              <div>
                <h3 class="selected-group-data-point-header">Selected<span >:</span></h3>
              </div>
              <div v-if="selectedGroupDatapoint.name">{{ selectedGroupDatapoint.name }}</div>
              <div v-if="!selectedGroupDatapoint.name">None</div>
            </div>
             <div class="selected-data-points-list">
              <div 
                class="no-data-points-selected-message" 
                v-if="!selectedGroupDatapoint.name"
              >
                  <p style="margin-top: 0;">No groups selected.</p>
                </div>
              <mds-list-group v-if="selectedGroupDatapoint !== 'None'">
                <span class="individual-data-points-list-container"
                  v-for="(datapoints, index) in selectedGroupDatapoint.datapointgroups"
                  :key="index"  :style="index !== 0 ? 'border-top: 0.8px solid #CCCCCC' : ''">
                    <mds-list-group-item
                     class="listGroup"
                      :text="datapoints.name"
                    ></mds-list-group-item>
                </span>
              </mds-list-group>
             </div>
          </div>
        </div>
      </div>
      <div>
      <notification-component
        v-if="showNotification"
        :notificationMessage="notificationMessage"
        :notificationType="notificationType"
        :keyType="notificationKey"
        :dismissDelay="5000"
        @close="showNotification = false"
      ></notification-component>
    </div>
    </mds-modal>
  </div>
</template>

<script>
import MdsModal from '@mds/modal';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsTabs, MdsTabsItem } from '@mds/tabs';
import MdsSearchField from '@mds/search-field';
import MdsForm from '@mds/form';
import MdsRadioButton from '@mds/radio-button';
import MdsCheckbox from '@mds/checkbox';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import {mapActions, mapMutations, mapGetters } from 'vuex';
import LoaderComponent from "../ui_component/loaderComponent.vue";
import Enums from "../../config/Enums.json";
import { MdsTag } from '@mds/tag'
import NotificationComponent from '../ui_component/notificationComponent.vue';
export default {
  components:{
    MdsModal,
    MdsButton,
    MdsButtonContainer,
    MdsTabs,
    MdsTabsItem,
    MdsSearchField,
    MdsForm,
    MdsRadioButton,
    MdsCheckbox,
    MdsListGroup,
    MdsListGroupItem,
    LoaderComponent,
    MdsTag,
    NotificationComponent
  },

  props: {
    showTemplatesTab: {
      type: Boolean,
      default: true
    },

    showGroupDatapointsTab: {
      type: Boolean,
      default: true
    },

    showIndividualDatapointsTab: {
      type: Boolean,
      default: true
    }
  },

  data(){
    return {
      showNotification:false,
      notificationMessage:'',
      notificationType:'',
      notificationKey:'',
      addDatapointKey:0,
      toggle: true,
      isTemplatesTabActive: true,
      isDatapointsTabActive: false,
      isGroupDatapointsTabActive: false,
      searchField: "",
      selectedTemplate: "None",
      selectedGroupDatapoint: "None",
      selectedDatapointsList: [],
      dummyList:[],
      bulkoperationsFileType:Enums.BulkOperationsFileType
    }
  },

  mounted() {
    if(this.showTemplatesTab === false && this.showIndividualDatapointsTab === false) {
      this.groupDatapointsTabActive();
    } else if(this.showTemplatesTab == false && this.showGroupDatapointsTab === false) {
      this.datapointsTabActive();
    } else {
      this.setShowCustomTemplateOption(true);
      this.setListOfDatapoints();
      this.setListOfTemplates();
    }
  },

  watch: {
    // to reselect the already selected individual data points
    isDatapointsTabActive(value) {
      if(value === true && this.showTemplatesTab === false) {
        this.dummyList = Object.assign(this.getAddedToGridDatapoints);
        for(let i=0; i < this.dummyList.length; i++) {
          let newObj = {
            id: this.dummyList[i].id,
            name: this.dummyList[i].name,
            isSelected: this.dummyList[i].isSelected
          }
          this.selectedDatapointsList.push(newObj);
          if(this.showTemplatesTab === false) {
            for(let i = 0; i < this.getModifiedDataPoints.length; i++) {
              for(let j = 0; j < this.selectedDatapointsList.length; j++) {
                if(this.getModifiedDataPoints[i].id === this.selectedDatapointsList[j].id) {
                  this.getModifiedDataPoints[i].isSelected = true;
                }
              }
            }
          }
        }
      }
    },

    // to reselect the already selected group data point
    isGroupDatapointsTabActive(value) {
      if(value === true && this.showTemplatesTab === false && this.showIndividualDatapointsTab === false) {
        let id = this.getAddedToGridGroupDatapointId;
        this.selectedGroupDatapoint = this.getModifiedGroupDatapoints.filter((elem) => elem.id === id)[0];
      }  
    }
  },

  computed: {
    ...mapGetters("bulkEdit", ["getEntityTypeId", "getTemplatesList", "getListOfDatapoints", "getAddedToGridDatapoints", "getModifiedDataPoints", "getModifiedGroupDatapoints", "getAddedToGridGroupDatapointId","getSelectedTemplateId"]),

    templateHeaders() {
      return this.selectedTemplate.headers.split(",");
    },

    templateResponseReceived() {
      if(this.getTemplatesList.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    datapointsResponseReceived() {
      if(this.getListOfDatapoints.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    transformDatapoints() {
      let response;
      if(this.showTemplatesTab === true) {
        response = this.getListOfDatapoints;
        if(response !== undefined) {
          response.forEach((elem) => {
            elem.isSelected = false;
          }); 
        }
        this.setModifiedDataPoints(response);
      } else {
        response = this.getModifiedDataPoints;
      }
      return response;
    },

    filteredIndividualDatapoints() {
      if(this.transformDatapoints !== undefined){
        let individualDatapoints = this.transformDatapoints.filter((elem) => elem.datapointgroups === null);
        this.setModifiedDataPoints(individualDatapoints);
      }
      return this.getModifiedDataPoints;
    },

    filteredGroupDatapoints() {
      if(this.transformDatapoints !== undefined) {
        let groupDatapoints = this.transformDatapoints.filter((elem) => elem.datapointgroups !== null);
        this.setModifiedGroupDatapoints(groupDatapoints);
      }
      return this.getModifiedGroupDatapoints;
    },

    isAddToGridDisabled() {
      if(this.isTemplatesTabActive){
         return this.selectedTemplate !== "None" ? false : true;
      }else if(this.isDatapointsTabActive){
         return (this.selectedDatapointsList.length !== 0 && this.selectedDatapointsList.length <= 20) ? false : true;
      }else if(this.isGroupDatapointsTabActive){
        return this.selectedGroupDatapoint !== "None" ? false : true;
      }else{
        return true
      }
      // return this.selectedTemplate !== "None" || this.selectedDatapointsList.length !== 0 || this.selectedGroupDatapoint !== 'None' ? false : true;
    },

    searchResultsForTemplate() {
      return this.getTemplatesList.filter((elem) => {
        if(elem.type === this.getEntityTypeId && (elem.templatetypeid == this.bulkoperationsFileType.MultipleDataPointsPerRow || elem.templatetypeid == this.bulkoperationsFileType.GroupDataPointsPerRow))
          {
            return elem.templatename.toLowerCase().includes(this.searchField.toLowerCase());
          }
      });
    },

    searchResultsForDatapoints() {
      return this.filteredIndividualDatapoints.filter((elem) => elem.name.toLowerCase().includes(this.searchField.toLowerCase()));
    },
    
    searchResultsForGroupDatapoints() {
      return this.filteredGroupDatapoints.filter((elem) => elem.name.toLowerCase().includes(this.searchField.toLowerCase()));
    }

  },

  methods: {
    ...mapActions("bulkEdit", ["setListOfTemplates", "setListOfDatapoints"]),
    ...mapMutations("bulkEdit", ["setShowCustomTemplateOption", "setSelectedTemplateId", "setSelectedDatapoints", "setModifiedDataPoints", "setModifiedGroupDatapoints", "setSelectedGroupDatapointId", "setAddedToGridDatapoints"]),

    templatesTabActive() {
       this.isTemplatesTabActive = true;
        this.isDatapointsTabActive = false;
        this.isGroupDatapointsTabActive = false;
      // if(this.selectedDatapointsList.length === 0 && this.selectedGroupDatapoint === 'None') {
      //   this.isTemplatesTabActive = true;
      //   this.isDatapointsTabActive = false;
      //   this.isGroupDatapointsTabActive = false;
      // } else if(this.selectedTemplate !== 'None') {
      //   this.isTemplatesTabActive = true;
      //   this.isDatapointsTabActive = false;
      //   this.isGroupDatapointsTabActive = false;
      // }
    },

    datapointsTabActive() {
       this.isDatapointsTabActive = true;
        this.isTemplatesTabActive = false;
        this.isGroupDatapointsTabActive = false;
      // if(this.selectedTemplate === 'None' && this.selectedGroupDatapoint === 'None') {
      //   this.isDatapointsTabActive = true;
      //   this.isTemplatesTabActive = false;
      //   this.isGroupDatapointsTabActive = false;
      // } else if(this.selectedDatapointsList.length !== 0) {
      //   this.isDatapointsTabActive = true;
      //   this.isTemplatesTabActive = false;
      //   this.isGroupDatapointsTabActive = false;
      // }
    },

    groupDatapointsTabActive() {
      this.isGroupDatapointsTabActive = true;
        this.isDatapointsTabActive = false;
        this.isTemplatesTabActive = false;
      // if(this.selectedTemplate === 'None' && this.selectedDatapointsList.length === 0) {
      //   this.isGroupDatapointsTabActive = true;
      //   this.isDatapointsTabActive = false;
      //   this.isTemplatesTabActive = false;
      // } else if(this.selectedGroupDatapoint !== 'None') {
      //   this.isGroupDatapointsTabActive = true;
      //   this.isDatapointsTabActive = false;
      //   this.isTemplatesTabActive = false;
      // }
    },

    datapointSelected(isSelected, datapoint) {
      if(isSelected === true) {
         datapoint.isSelected = true;
         this.selectedDatapointsList.push(datapoint);
        if(this.selectedDatapointsList.length > 20){
          this.notificationKey = "error-default";
          this.notificationMessage ="You can't select more than 20 datapoints";
          this.notificationType = "error";
          this.showNotification = true;
        }
      } else {
        datapoint.isSelected = false;
        this.selectedDatapointsList = this.selectedDatapointsList
          .filter((elem) => elem.id !== datapoint.id);
      }
    },

    datapointRemoved(datapoint) {
      datapoint.isSelected = false;
      this.getModifiedDataPoints.forEach((elem) => {
        if(elem.id === datapoint.id) {
          elem.isSelected = false
        }
      });
      this.selectedDatapointsList = this.selectedDatapointsList
        .filter((elem) => elem.id !== datapoint.id);
    },

   async addToGrid() {
      if(this.isTemplatesTabActive === true) {
        let dummyobj = [];
        this.setSelectedTemplateId(this.selectedTemplate);
        let selectedTemplateHeaders = this.templateHeaders;
        if(this.selectedTemplate.templatetypeid === this.bulkoperationsFileType.MultipleDataPointsPerRow) {
          for( let i=0; i < selectedTemplateHeaders.length; i++ ) {
            this.filteredIndividualDatapoints.forEach((elem) => {
              if(elem.name.includes(selectedTemplateHeaders[i])) {
                if(elem.name.length === selectedTemplateHeaders[i].length) {
                  let newObj = {
                    id: elem.id,
                    name: elem.name,
                    isSelected: true
                  }
                  dummyobj.push(newObj);
                }
              }
            });
          }
        }else if(this.selectedTemplate.templatetypeid === this.bulkoperationsFileType.GroupDataPointsPerRow){
          let group = await this.getGroupDetails(selectedTemplateHeaders)
          group.isSelected =true
            this.$emit('groupDatapointAddedToGrid',group);
          this.$emit("modalAction", false);
        }
        // this.setAddedToGridDatapoints(dummyobj);
        if(this.selectedTemplate.templatetypeid === 2) {
          // this.selectedDatapointsList = this.getAddedToGridDatapoints;
          this.$emit('templateAddedToGrid', this.selectedTemplate, dummyobj);
        }
        this.$emit("modalAction", false);
      } else if(this.isDatapointsTabActive === true) {
        //   for( let i=0; i < this.selectedDatapointsList.length; i++ ) {
        //     let newObj = {
        //       id: this.selectedDatapointsList[i].id,
        //       name: this.selectedDatapointsList[i].name,
        //       isSelected: this.selectedDatapointsList[i].isSelected
        //     }
        //     this.dummyList.push(newObj);
        //   }
        if(this.showTemplatesTab === true  && this.showIndividualDatapointsTab === true ){
          this.setSelectedTemplateId(0);
        }
        this.setSelectedDatapoints(this.selectedDatapointsList);
        this.$emit('datapointsAddedToGrid', this.selectedDatapointsList);
        this.$emit("modalAction", false);
      } else if(this.isGroupDatapointsTabActive === true) {
        this.setSelectedTemplateId(0);
        this.getModifiedGroupDatapoints.forEach((elem) => elem.isSelected = false);
        this.selectedGroupDatapoint.isSelected = true;
        this.setSelectedGroupDatapointId(this.selectedGroupDatapoint);
        this.$emit('groupDatapointAddedToGrid', this.selectedGroupDatapoint);
        this.$emit("modalAction", false);
      }
    },
    getGroupDetails(selectedTemplateHeaders){
      for(let j=0;j<  selectedTemplateHeaders.length;j++){
            for(let k=0;k<this.filteredGroupDatapoints.length;k++){
              for(let l=0;l<this.filteredGroupDatapoints[k].datapointgroups.length;l++){
                if(this.filteredGroupDatapoints[k].datapointgroups[l].name.includes(selectedTemplateHeaders[j])){
                  return this.filteredGroupDatapoints[k]
                }
              }
            }
          }
    },
    cancel() {
      if(this.showTemplatesTab === false && this.showGroupDatapointsTab === false) {

        // assigning already added to grid data points back to the list & ensuring they are all selected.
        this.selectedDatapointsList = this.getAddedToGridDatapoints;
        this.selectedDatapointsList.forEach((elem) => elem.isSelected = true);

        let alreadySelectedDatapoints = this.getAddedToGridDatapoints;
        let filteredModifiedDatapoints = this.getModifiedDataPoints.filter((elem) => elem.isSelected === true);

        if(filteredModifiedDatapoints.length === 0) {
          for(let i = 0; i < filteredModifiedDatapoints; i++) {
            for(let j = 0; j < alreadySelectedDatapoints; j++) {
              if(filteredModifiedDatapoints[i].id === alreadySelectedDatapoints[j].id) {
                filteredModifiedDatapoints[i].isSelected = true;
              }
            }
          }
        }

        // functionality to ensure already added data points checkboxes are checked and rest are unchecked again.
        if(filteredModifiedDatapoints.length > alreadySelectedDatapoints.length) {
          const filterArray = (filteredModifiedDatapoints, alreadySelectedDatapoints) => {
            const filtered = filteredModifiedDatapoints.filter(elem => {
              return alreadySelectedDatapoints.indexOf(elem) === -1;
            });
            return filtered;
          };
          filterArray(filteredModifiedDatapoints, alreadySelectedDatapoints).forEach(elem => {
            elem.isSelected = false;
          });
        }

        // functionality to ensure already added data points checkboxes are checked and rest are unchecked again.
        if(filteredModifiedDatapoints.length < alreadySelectedDatapoints.length) {
          const filterArray = (alreadySelectedDatapoints, filteredModifiedDatapoints) => {
            const filtered = alreadySelectedDatapoints.filter(elem => {
              return filteredModifiedDatapoints.indexOf(elem) === -1;
            });
            return filtered;
          };
          let unselectedDatapoints = filterArray(alreadySelectedDatapoints, filteredModifiedDatapoints);
          unselectedDatapoints.forEach((elem) => elem.isSelected = true);
        }
      }

      // functionality to reselect already selected Group datapoint
      if(this.showTemplatesTab === false && this.showGroupDatapointsTab === true) {
        let id = this.getAddedToGridGroupDatapointId;
        this.getModifiedGroupDatapoints.forEach((elem) => elem.isSelected = false);
        let selectedGroupDatapoint = this.getModifiedGroupDatapoints.filter((elem) => elem.id === id);
        selectedGroupDatapoint[0].isSelected = true;
      }
      
      this.toggle = false;
      this.$emit('modalAction', false);
      if(this.showTemplatesTab) {
        // this.$router.push("/entity-list").catch(() => {});
        this.$emit("onCancelClick")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  body {
    margin-bottom: 0px;
    padding: 0px;
  }
  .data-point_wrapper {
    display: inline-block;
  }

  .templates-section-container, .data-points-section-container, .group-data-points-section-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .select-a-template-header, .select-data-points-header, .select-a-group-data-point-header {
    margin-top: 20px;
    margin-bottom: 13px;
  }

  .template-list, .data-points-list, .group-data-point-list {
    margin-top: 16px;
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
  }
  .template-list_wrapper {
    display: inline-block;
  }
  .group-data-point-list {
    display: inline-block;
  }

  .template, .data-point, .group-data-point {
    margin-top: 8px;
  }

  .selected-template-section, .selected-data-points-section, .selected-group-data-point-section {
    margin-left: 32px;
    margin-top: 24px;
  }

  .selected-template-header-container, .selected-data-points-header-container, .selected-group-data-point-header-container {
    display: grid;
    grid-template-columns: 18% auto;
  }

  .selected-template-header, .selected-data-points-header, .selected-group-data-point-header {
    margin: 0px;
    padding: 0px;
  }

  .no-data-points-selected-message {
    color: #1E1E1E;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .selected-data-points-list {
    margin-top: 10px;
    min-height: 440px;
    max-height: 440px;
    overflow-y: auto;
  }

  .individual-data-points-list-container {
    display: grid;
    grid-template-columns: 95% 5%;
    align-items: center;
  }

  .remove-icon {
    padding-top: 5px;
  }
  
  /* MDS styling modifications */
  .mds-modal___VueMDS3Demo .mds-section__content___VueMDS3Demo {
    padding-top: 5px;
  }

  .disableTabs{
    opacity: 0.4;
  }
  #add-datapoint-modal::v-deep  .disableTabs .mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo{
    cursor: not-allowed !important;
    box-shadow: none!important;

  }
  #add-datapoint-modal::v-deep .listGroup .mds-list-group__link___VueMDS3Demo{
    padding-left: 0px;
  }
</style>