<template>
  <div class="bulk-edit-entity" :class="{isFilterOpen: checkFilterOpen && !showBulkEditGrid}">
    <loader-component v-if="loading" />
    <mds-layout-grid v-if="!showBulkEditGrid">
      <mds-row style="margin: 0">
        <div
          :class="[
            { showFilterLeftBlock: showFilterPanel },
            'leftBlock'
          ]"
          :style="cssVariables"
        >
          <div>
            <div>
              <h2 class="template-header">Entities</h2>
            </div>
            <div
              style="
                width: 100%;
                border-bottom: 1px solid #cccccc;
                margin-bottom: 10px;
              "
            >
              <div style="display: flex">
                <p
                  class="flex-buttons"
                  style="font-size: 16px;display: flex;
    justify-content: center;
    align-items: center;"
                >
                  {{ this.selectedEntities.length }} Selected
                </p>
                <div class="vertical-line"></div>
                <!-- <button-component
                  class="flex-buttons"
                  :onClick="addDataPoints"
                  buttonName="Add Entity"
                  buttonVariation="flat"
                  iconName="plus"
                  buttonSize="large"
                >
                </button-component> -->

                <mds-button
                  class="flex-buttons"
                  style="white-space: nowrap"
                  @click="onClickImportData()"
                  variation="flat"
                  icon="upload"
                  size="large"
                  type="button"
                  :disabled="!((permissions.includes('bulk-edit-header-import-data')) || permissions.includes('*'))"
                >Import Data
                </mds-button>
                <mds-button
                  class="flex-buttons"
                  @click="setSelectedEntities()"
                  buttonName="Bulk Edit"
                  :disabled="
                    !((permissions.includes('bulk-edit-header-bulk-edit')) || permissions.includes('*')) ||
                    !(
                      this.selectedEntities.length > 0 &&
                      this.selectedEntities.length <= 100 && disableBulkEditButtonEntityTypeReference
                    )
                  "
                  variation="flat"
                  icon="pencil"
                  size="large"
                  type="button"
                >Bulk Edit
                </mds-button>
              </div>
            </div>
          </div>
          <div class="table-details">
            <list-group-table-copy
              @updateEntities="updateEntitiesHandler"
              :rowData.sync="rows"
              @updateRowData="updateRowData"
              :filter="filter"
              :key="listGroupComponent"
              :offset="offset"
              :displayChildEntities="showChildEntites"
              :entityTypeId="selectedEntityType"
              @entityClicked="entityListClicked"
              @entityCollasped="entityListCollaspe"
              @columnSorted="sortColumnsOnClick"
            ></list-group-table-copy>
            <mds-pagination
              :class="[
                showFilterPanel === true
                  ? 'move-pagination-show-info'
                  : '',
                'pagination-component',
              ]"
              v-if="entityListLength > 0 && rows.length >0"
              :page="pageNumber"
              show-items-info
              show-items-select
              :total-items="totalItems"
              :pageSize="getPageSize"
              :pageSizes="[10, 20, 50]"
              @mds-pagination-page-changed="paginateTable"
            ></mds-pagination>
          </div>
        </div>
        <div
          :class="[
            { showFilterRightBlock: showFilterPanel },
            'rightBlock'
          ]"
          :style="cssVariables"
        >
          <transition-group name="fade" class="group-4">
            <!-- <filter-panel></filter-panel> -->
            <!-- @filterEvent="filterEvent" -->
            <!-- <checkbox-filter :key="checkBoxFilterKey" @onFilterChange="checkBoxChange" @filterEvent="searchFilter" :selectedEntity="selectedEntityType" :filterData="filterObject" searchInputLable="Entities" @clearInputFilter="clearTextFilter" @hideFilterPanel="closeFilterPanel" :isFilterPanelOpen="showFilterPanel" @showFilterPanel="openFilterPannel" @clearAllFilters="clearAllFilters"></checkbox-filter> -->

            <filter-panel
              :key="checkBoxFilterKey"
              @onFilterComboValue="checkBoxChange"
              @filterEvent="searchFilter"
              :selectedEntity="selectedEntityType"
              :filterData="filterObject"
              searchInputLable="Entities"
              searchInputPlaceHolder="Search by name or identifier"
              :showSearchInput="true"
              :searchInputText="filterPanelSearchInputText"
              @clearInputFilter="clearTextFilter"
              @hideFilterPanel="closeFilterPanel"
              :isFilterPanelOpen="showFilterPanel"
              @showFilterPanel="openFilterPannel"
              @clearAllFilters="clearAllFilters"
              :hideFilterPanelProp="hideFilterPanel"
              :selectCheckboxId="checkboxFilterId"
              :childEntity="showChildEntites"
              @multiSelectFilterChange="multiSelectFilterChange"
              @onFilterSwitch="displayChildEntites"
              :isDisplayChildEntityDisabled="disableDisplayChildEntityType"
            ></filter-panel>
          </transition-group>
        </div>
      </mds-row>
    </mds-layout-grid>
    <div v-if="showBulkEditGrid">
      <bulk-edit-grid-table
        @onCloseBulkEditGridTable="closeBulkEditGrid"
        @onCloseBulkEditGridTableWithMessage="closeBulkEditGridWithMessage"
      ></bulk-edit-grid-table>
    </div>
    <div>
      <notification-component
        v-if="showNotification"
        :notificationMessage="notificationMessage"
        :notificationType="notificationType"
        :keyType="notificationKey"
        :dismissDelay="5000"
        @close="showNotification = false"
      ></notification-component>
    </div>
  </div>
</template>
<script>
import { MdsButton } from "@mds/button";
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import ListGroupTableCopy from "../common_components/ListGroupTable copy.vue";
// import CheckboxFilter from "../common_components/CheckboxFilter.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import MdsPagination from "@mds/pagination";
import { dateFormatterMixin } from "../../mixins/date.js";
import BulkEditGridTable from "./BulkEditGridTable.vue";
import FilterPanel from "../common_components/FilterPanel.vue";
import NotificationComponent from "../ui_component/notificationComponent.vue";
// import FilterPanel from '../common_components/FilterPanel.vue';
export default {
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsButton,
    ListGroupTableCopy,
    // CheckboxFilter,
    MdsPagination,
    LoaderComponent,
    BulkEditGridTable,
    FilterPanel,
    NotificationComponent,
    // FilterPanel
  },

  mixins: [dateFormatterMixin],

  data() {
    return {
      paginationPageSize:10,
      isGridTableClosed:false,
      showChildEntites: false,
      filterPanelSearchInputText: "",
      filterStatusChecked: [],
      orginalRows: [],
      showFilterPanel: false,
      hideFilterPanel: true,
      checkFilterOpen: false,
      pageNumber: 1,
      notificationMessage: "",
      notificationType: "",
      notificationKey: "",
      showNotification: false,
      showBulkEditGrid: false,
      rows: [],
      filter: "",
      listGroupComponent: 0,
      selectedEntities: [],
      loading: false,
      offset: 0,
      checkBoxFilterKey: 0,
      disableBulkEditButtonEntityTypeReference:true,
      disableDisplayChildEntityType:false,
      selectedEntityType: "1",
      checkboxFilterId: [],
      MultiSelection: [],
      filterObject: [
        {
          filterHeader: "",
          selectionType: "",
          filterList: [],
        },        
        {
          filterHeader: "Status",
          selectionType: "multiple",
          filterList: [
            {
              lable: "Approved",
              id: 1,
              isChecked: false,
            },
            {
              lable: "Draft",
              id: 2,
              isChecked: false,
            },
          ],
        },
      ],
    };
  },

  props: {
    menuwidth: {
      type: String,
    },
    passEntityDetailsObject: {
      type: Object,
    },
  },

  watch: {
    // this displays a notification when user has selected more than 100 rows & selectedEntityType <= 4.
    getCheckedEntites(value) {
      if (value.length > 100 && this.selectedEntityType <= "4") {
        const obj = {
          response: { isError: false },
          ntfnMsg: "You cannot select more than 100 rows for bulk edit.",
          ntfnType: "error",
          ntfnKey: "error-default",
        };
        this.notificationResponse(obj);
      }
    },

    passEntityDetailsObject(value) {
      this.selectedEntityType = value.selectedEntityType;
      this.filterPanelSearchInputText = value.searchInput;
      this.setSelectedFilterEntity(value.selectedEntityType);
      this.searchFilter(value.searchInput);
      if(this.$route.path === "/data-management/entities") {
        this.checkBoxFilterKey++;
        this.hideFilterPanel = false;
        this.showFilterPanel = true;
      }
    },
  },

  computed: {
    ...mapGetters("bulkEdit", [
      "getEntities",
      "getChildEntities",
      "getSelectedFilterEntity",
      "getPageNumber",
      "getPageSize",
      "getFirstItem",
      "getLastItem",
      "getCheckedEntites",
      "getIsAscending",
    ]),
    ...mapGetters("entity",["getReferenceEntityTypesDropdown"]),
    // ...mapGetters("layout", ["getSearchEntities"]),
    // css variable for dynamic filter styles
    cssVariables() {
      return {
        "--menuWidth": this.menuwidth,
      };
    },
    totalItems() {
      return this.getEntities.data.totalitems;
    },
    entityListLength() {
      if (this.getEntities.data !== undefined) {
        if (this.getEntities.data.list.length > 0) {
          return this.getEntities.data.list.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    permissions () {
      return this.$store.state.auth.permissions;
    },
  },
  created() {
    if (
      this.$route.params.searchInput &&
      this.$route.params.selectedEntityType
    ) {
      this.hideFilterPanel = false;
      this.showFilterPanel = true;
      this.selectedEntityType = this.$route.params.selectedEntityType;
      this.filterPanelSearchInputText = this.$route.params.searchInput;
      this.setSelectedFilterEntity(this.$route.params.selectedEntityType);
      this.searchFilter(this.$route.params.searchInput);
    }
  },
  async mounted() {
    if (
      this.$route.params.searchInput &&
      this.$route.params.selectedEntityType
    ) {
      // this.hideFilterPanel = false;
      // this.showFilterPanel = true;
      // this.setSelectedFilterEntity(this.$route.params.selectedEntityType);
      // this.searchFilter(this.$route.params.searchInput);
    } else {
      // -------------------------------
      this.getSearchEntitiesTypeJSON();
      this.selectedEntityType = "1";
      this.setSelectedFilterEntity("1");
    }
    this.DropdownReferenceEntityType();
  },
  methods: {
    ...mapActions("bulkEdit", ["fetchEntityList", "fetchChildEntityList"]),
    ...mapActions("entity",["fetchReferenceEntityTypesDropdown"]),
    ...mapMutations("bulkEdit", [
      "setSelectedFilterEntity",
      "setPageNumber",
      "setPageSize",
      "setOrderby",
      "setIsAscending",
      "setFileId",
      "setFileToNull",
      "setShowCustomTemplateOption",
      "setTemplateId",
      "setTemplateToNull",
      "setListOfDatapoints",
      "setAddedToGridDatapoints",
      "setListOfTemplates",
      "setFirstItem",
      "setLastItem",
      "emptyCheckedEntites",
      "setSelectedTemplateId"
    ]),
    ...mapActions("layout", ["fetchSearchEntities"]),
    // Filter entity-type dropdown
    async DropdownReferenceEntityType() {
      let param={
        showAll : true,
      };
      await this.fetchReferenceEntityTypesDropdown(param)
      this.filterObject[0].filterHeader = "Type";
      this.filterObject[0].selectionType = "dropdown";
      this.getReferenceEntityTypesDropdown.forEach((item)=>{
              let obj={};
              obj.id = item.entitytypeid.toString();
              obj.lable = item.entitytypedescription.toString();
              this.filterObject[0].filterList.push(obj);
        })
      },
    updateRowData(val){
    this.rows=val
    // for(let i=0;i<val.length;i++){
    //   for(let j=0;j<this.rows.length;j++){
    //     if(val[i].id == this.rows[j].id){
    //       console.log("****");
    //       this.rows[j].showAddToolTip=val[i].showAddToolTip
    //       this.rows[j].showEditToolTip=val[i].showEditToolTip
    //       this.rows[j].showReportToolTip=val[i].showReportToolTip
    //     }
    //   }
    // }
    },
    displayChildEntites(value) {
      this.showChildEntites = value;
      this.emptyCheckedEntites([]);
    },
    onClickImportData() {
      this.$router.push("/data-management/import-data").catch(() => {});
    },
    closeFilterPanel() {
      this.checkFilterOpen = false;
      this.showFilterPanel = false;
    },
    openFilterPannel() {
      this.checkFilterOpen = true;
      this.showFilterPanel = true;
    },
    getModelData() {
      let response = this.getEntities.data.list;

      if (response !== undefined) {
        this.rows = this.getEntities.data.list.map(this.modifyEntityList);
        this.orginalRows = this.getEntities.data.list.map(
          this.modifyEntityList
        );
        
        // if(this.filterStatusChecked.length >0){
        //   this.MultiSelection=[]
        //   this.checkboxFilterId=[]
        //   console.log(this.filterObject);
        //   for(let i=0;i<this.filterObject.length;i++){
        //     if(this.filterObject[i].filterHeader== 'Status'){
        //       for(let j=0;j<this.filterObject[i].filterList.length;j++){
        //          this.filterObject[i].filterList[j].isChecked=false
        //       }
        //     }
        //   }
        // }
        // this.listGroupComponent += 1;
        this.loading = false;
      }
    },
   
    async sortColumnsOnClick(event) {
      if(!this.isGridTableClosed){
        this.paginationPageSize=this.getPageSize
          if (event.sortOrder === -1) {
          this.paginateTable({
            firstItem: 1,
            lastItem: 10,
            page: 1,
            pageSize: this.getPageSize,
            IsAscending: false,
          });
        } else {
          this.paginateTable({
            firstItem: 1,
            lastItem: 10,
            page: 1,
            pageSize: this.getPageSize,
            IsAscending: true,
          });
        }
      }else{
        this.isGridTableClosed = false
      }
      
    },

    async paginateTable(arg) {
      this.loading = true;
      this.pageNumber = arg.page;
      this.setPageNumber(arg.page);
      this.setPageSize(arg.pageSize);
      this.setLastItem(arg.lastItem);
      this.setFirstItem(arg.firstItem);
      this.paginationPageSize=arg.pageSize
      if (arg.IsAscending == undefined) {
        this.setIsAscending(this.getIsAscending);
      } else {
        this.setIsAscending(arg.IsAscending);
      }
      let status = undefined;
      if (this.filterStatusChecked.length > 0) {
        for (let i = 0; i < this.filterStatusChecked.length; i++) {
          if (status == undefined) {
            status = this.filterStatusChecked[i].lable;
          } else {
            status = status + "," + this.filterStatusChecked[i].lable;
          }
        }
      }
      var paramsData = {
        entityId: undefined,
        entityTypeId: this.selectedEntityType,
        status: status,
        search: this.filterPanelSearchInputText,
      };
      await this.fetchEntityList(paramsData);
      if (this.getModelData()) {
        this.paginatedRows = this.getModelData().slice(
          arg.firstItem - 1,
          arg.lastItem
        );
      }
      await this.childEntitiesSelectedDetails()
    },

   clearAllFilters() {
      this.filterPanelSearchInputText = "";
      this.selectedEntityType = "1";
      this.setSelectedFilterEntity("1");
      let selectedCheckboxes = document.querySelectorAll(
        "input[type=checkbox]:checked"
      );
      selectedCheckboxes.forEach((elem) => elem.click());
      this.selectedEntities=[];
       this.emptyCheckedEntites([]);
       this.listGroupComponent++
      // this.paginateTable({
      //   firstItem: 1,
      //   lastItem: 10,
      //   page: 1,
      //   pageSize: this.getPageSize,
      //   IsAscending: this.getIsAscending,
      // });
    },
    filterEvent(searchText) {
      this.filter = searchText;
    },
    
    // to clear all the checkboxes in the table when we search or clear text from search
    clearSelection() {
      let table = document.querySelector("#list-group-table")
      if(table !== null) {
        let selectedCheckboxes = table.querySelectorAll(
          "input[type=checkbox]:checked"
        );
        selectedCheckboxes.forEach((elem) => elem.click());
        this.selectedEntities=[];
        this.emptyCheckedEntites([]);
      }
    },

    clearTextFilter() {
      this.filterPanelSearchInputText=''
      this.paginateTable({
        firstItem: 1,
        lastItem: 10,
        page: 1,
        pageSize: this.getPageSize,
        IsAscending: this.getIsAscending,
      });
      this.clearSelection();
    },
    async searchFilter(searchText) {
      // this.loading = true;
      // this.setPageNumber(1);
      this.filterPanelSearchInputText = searchText;
      this.paginateTable({
        firstItem: 1,
        lastItem: 10,
        page: 1,
        pageSize: this.getPageSize,
        IsAscending: this.getIsAscending,
      });
      this.clearSelection();
      // this.loading = false;
    },
    checkBoxChange(event) {
      this.loading = true;
      for(let i=0;i<this.getReferenceEntityTypesDropdown.length;i++){
        if(this.getReferenceEntityTypesDropdown[i].entitytypeid == event){
          if(this.getReferenceEntityTypesDropdown[i].refrencetype){
            this.disableBulkEditButtonEntityTypeReference=false
            this.disableDisplayChildEntityType=true
          }else{
            this.disableBulkEditButtonEntityTypeReference=true
            if(this.getReferenceEntityTypesDropdown[i].childentityid == 0){
              this.disableDisplayChildEntityType=true
            }else{
              this.disableDisplayChildEntityType=false
            }
          }
          break;
        }
      }
      this.offset = event - 1;
      this.filterPanelSearchInputText = "";
      this.setSelectedFilterEntity(event);
      this.selectedEntityType = event;
      this.emptyCheckedEntites([]);
      this.selectedEntities = [];
      // this.paginateTable({
      //   firstItem: 1,
      //   lastItem: 10,
      //   page: 1,
      //   pageSize: this.getPageSize,
      //   IsAscending: this.getIsAscending,
      // });
      this.listGroupComponent++
      // this.$store.dispatch("bulkEdit/getEntityList", event).then((data) => {
      //   this.rows = data.list.map(this.modifyEntityList);
      //   this.listGroupComponent += 1;
      //   this.loading = false;
      // });
    },
    modifyEntityList(item) {
      let date = this.convertUTCDate(item.updateddate);
      // let date = item.updateddate ? new Date(item.updateddate).toLocaleDateString('en-US') : '-';
      var status = "";
      var isSelected = false;
      if (item.isapproved) {
        status = "Approved";
      } else if (item.isdraft) {
        status = "Draft";
      } else {
        status = "Pending";
      }
      for (let i = 0; i < this.getCheckedEntites.length; i++) {
        if (this.getCheckedEntites[i].id == item.entityid) {
          isSelected = true;
          break;
        } else {
          if (i == this.getCheckedEntites.length - 1) {
            isSelected = false;
          }
        }
      }
      return Object.assign(item, {
        showEditToolTip: false,
        showAddToolTip: false,
        showReportToolTip: false,
        isClicked: false,
        isChildEntityClicked:false,
        id: item.entityid,
        createddate: date,
        paddingLeft: 0,
        entitytypedescription: item.entitytypedescription,
        entityid: item.entityid.toString(),
        status: status,
        selected: isSelected,
      });
    },
    async updateEntitiesHandler(data) {
      this.selectedEntities = data;
      await this.childEntitiesSelectedDetails()
    },
    childEntitiesSelectedDetails(){
       if(this.getCheckedEntites.length > 0){
        for(let i=0;i<this.rows.length;i++){
            this.rows[i].isChildEntityClicked=false
        }
        for(let i=0;i<this.getCheckedEntites.length;i++){
          if(this.getCheckedEntites[i].parentEntityDetails.length == 0){
            if(this.getCheckedEntites[i].parententityid != 0){
              this.markChildsParentOnChildSelected(this.getCheckedEntites[i],i)
            }
          }else{
            for(let j=0;j<this.getCheckedEntites[i].parentEntityDetails.length;j++){
              for(let k=0;k<this.rows.length;k++){
                if(this.getCheckedEntites[i].parentEntityDetails[j].entitytypeid == this.rows[k].entitytypeid && this.getCheckedEntites[i].parentEntityDetails[j].entityid == this.rows[k].entityid){
                  this.rows[k].isChildEntityClicked=true
                }
              }
            }
          }
          
        }
      }else{
        for(let i=0;i<this.rows.length;i++){
            this.rows[i].isChildEntityClicked=false
          
        }
      }
    },
    markChildsParentOnChildSelected(checkedEntityObject,index){
      for(let i=0;i<this.rows.length;i++){
        if(checkedEntityObject.parententityid == this.rows[i].entityid){
          this.rows[i].isChildEntityClicked=true
           let parentObject={
              entitytypeid:this.rows[i].entitytypeid,
              entityid:this.rows[i].entityid
            }
            this.getCheckedEntites[index].parentEntityDetails.push(parentObject)
          if(this.rows[i].parententityid != 0){
           
            this.markChildsParentOnChildSelected(this.rows[i],index)
          }
        }else{
          if(i == this.rows.length-1){
            this.rows[i].isChildEntityClicked=false
          }
        }
      }
    },
    setSelectedEntities() {
      // const type = this.rows.filter((entity) => entity.id ===  this.getCheckedEntites[0].id)[0].entitytypeid
      // this.rows.filter((entity) => entity.id ==  this.selectedEntities[0])[0].entitytypeid;
      if(this.selectedEntities.length > 0 
          && this.selectedEntities.length <= 100
      ) {
        const type = this.getCheckedEntites[0].entitytypeid;
        this.setListOfTemplates([]);
        this.setListOfDatapoints([]);
        this.setAddedToGridDatapoints([])
        this.$store
          .dispatch("bulkEdit/setSelectedEntityData", {
            entities: this.selectedEntities,
            type,
          })
          .then(() => {
            this.showBulkEditGrid = true;
            // this.$router.push("/bulkeditgridtable");
          });
      }
    },
    closeBulkEditGrid() {
      this.isGridTableClosed=true;
      this.showBulkEditGrid = false;
      this.setSelectedTemplateId(0);
    },
    closeBulkEditGridWithMessage(message) {
      this.isGridTableClosed=true;
      this.showBulkEditGrid = false;
      this.setSelectedTemplateId(0);
      const obj = {
        response: {
          isError: false,
        },
        ntfnMsg: message,
        ntfnType: "success",
        ntfnKey: "success-default",
      };
      this.notificationResponse(obj);
    },
    async entityListClicked(value, vl) {
      this.loading = true;
      let status = undefined;
      this.rows[vl.rowIndex].isClicked=true
      if (this.filterStatusChecked.length > 0) {
        for (let i = 0; i < this.filterStatusChecked.length; i++) {
          if (status == undefined) {
            status = this.filterStatusChecked[i].lable;
          } else {
            status = status + "," + this.filterStatusChecked[i].lable;
          }
        }
      }
      if (value.entityid) {
        var paramsData = {
          entityId: value.entityid,
          status: status,
        };
        await this.fetchChildEntityList(paramsData);
        if (!this.getChildEntities.isError) {
          for (var i = 0; i < this.rows.length; i++) {
            if (i == vl.rowIndex) {
              var index = i + 1;
              for (var j = 0; j < this.getChildEntities.data.list.length; j++) {
                let selected =false;
                for (let i = 0; i < this.getCheckedEntites.length; i++) {
                    if (this.getCheckedEntites[i].id == this.getChildEntities.data.list[j].entityid) {
                      selected = true;
                      break;
                    } else {
                      if (i == this.getCheckedEntites.length - 1) {
                        selected = false;
                      }
                    }
                  }
                this.getChildEntities.data.list[j]["isClicked"] = false;
                this.getChildEntities.data.list[j]["isChildEntityClicked"]=false;
                this.getChildEntities.data.list[j]["selected"]=selected
                this.getChildEntities.data.list[j]["showEditToolTip"] = false;
                this.getChildEntities.data.list[j]["showAddToolTip"] = false;
                this.getChildEntities.data.list[j]["showReportToolTip"] = false;
                this.getChildEntities.data.list[j]["style"]={backgroundColor:''}
                this.getChildEntities.data.list[j]["id"] = this.getChildEntities.data.list[j].entityid;
                if (this.rows[i].paddingLeft == 0) {
                  this.getChildEntities.data.list[j]["paddingLeft"] = 16;
                } else {
                  this.getChildEntities.data.list[j]["paddingLeft"] =
                    this.rows[i].paddingLeft + 16;
                }
                this.getChildEntities.data.list[j].entityid = this.getChildEntities.data.list[j].entityid.toString();
                // let date = this.getChildEntities.data.list[j].updateddate ? new Date(this.getChildEntities.data.list[j].updateddate).toLocaleDateString('en-US') : '-';
                let date = this.convertUTCDate(this.getChildEntities.data.list[j].updateddate);
                let statusDetails = "";
                if (this.getChildEntities.data.list[j].isapproved) {
                  statusDetails = "Approved";
                } else if (this.getChildEntities.data.list[j].isdraft) {
                  statusDetails = "Draft";
                } else {
                  statusDetails = "Pending";
                }
                this.getChildEntities.data.list[j].status = statusDetails
                this.getChildEntities.data.list[j].createddate = date;
                this.rows.splice(index, 0, this.getChildEntities.data.list[j]);
                this.orginalRows.splice(index,0,this.getChildEntities.data.list[j]);
                index++;
                // break;
              }
            }
          }
           await this.childEntitiesSelectedDetails()
        }
        this.loading = false;
      }
    },
    entityListCollaspe(entityId,cell) {
      if(cell != undefined){
         this.rows[cell.rowIndex].isClicked=false
      }
      for (let i = this.rows.length - 1; i >= 0; i--) {
        if (this.rows[i].parententityid == entityId) {
          this.entityListCollaspe(this.rows[i].id,undefined);
          this.rows.splice(i, 1);
        }
      }
    },
    // need to change once API will be ready
    async getSearchEntitiesTypeJSON() {
      // this.loading= true;
      //   let searchEntitiesInput = {
      //     showAll: false,
      //     showItem: 3,
      //     query: "hsbc",
      //   };
      //   await this.fetchSearchEntities(searchEntitiesInput);
      // let response = this.getSearchEntities;
      // if (response !== undefined) {
      //   this.filterObject[0].filterHeader = "";
      //   this.filterObject[0].selectionType = "dropdown";
      //   for (let i = 0; i < response.length; i++) {
      //     let data = {};
      //     data.id = response[i].id;
      //     data.lable = response[i].type;
      //     this.filterObject[0].filterList.push(data);
      //   }
      //   // this.loading = true;
      // }
    },

    async onStatusFilterClick() {
      this.paginateTable({
            firstItem: 1,
            lastItem: 10,
            page: 1,
            pageSize: this.getPageSize,
            IsAscending: undefined,
          });
      // let status = undefined;
      // if (this.filterStatusChecked.length > 0) {
      //   for (let i = 0; i < this.filterStatusChecked.length; i++) {
      //     if (status == undefined) {
      //       status = this.filterStatusChecked[i].lable;
      //     } else {
      //       status = status + "," + this.filterStatusChecked[i].lable;
      //     }
      //   }
      // }
      // this.loading = true;
      // var paramsData = {
      //   status: status,
      //   entityTypeId: this.selectedEntityType,
      //   search: this.filterPanelSearchInputText,
      // };
      // await this.fetchEntityList(paramsData);
      // if (this.getModelData()) {
      //   this.paginatedRows = this.getModelData();
      // }
      // this.loading = false;
    },
    multiSelectFilterChange(event, value, headerTypeDetails) {
      if (headerTypeDetails == "Status") {
        if (this.filterStatusChecked.length > 0) {
          for (let i = 0; i < this.filterStatusChecked.length; i++) {
            if (this.filterStatusChecked[i].lable == value.lable) {
              break;
            } else {
              if (i == this.filterStatusChecked.length - 1) {
                this.filterStatusChecked.push(value);
              }
            }
          }
        } else {
          this.filterStatusChecked.push(value);
        }

        if (event == false) {
          let i = this.filterStatusChecked.length;
          while (i--) {
            if (this.filterStatusChecked[i].lable == value.lable) {
              this.filterStatusChecked.splice(i, 1);
            }
          }
        }
        for (let i = 0; i < this.filterObject.length; i++) {
          if (this.filterObject[i].filterHeader == headerTypeDetails) {
            for (let j = 0; j < this.filterObject[i].filterList.length; j++) {
              if (this.filterObject[i].filterList[j].id == value.id) {
                this.filterObject[i].filterList[j].isChecked = event;
                break;
              }
            }
          }
        }
        this.onStatusFilterClick();
        // this.listGroupComponent += 1;
      }
    },
    async notificationResponse({ response, ntfnMsg, ntfnType, ntfnKey }) {
      if (response.isError == false) {
        this.notificationMessage = ntfnMsg;
        this.notificationType = ntfnType;
        this.showNotification = true;
        this.notificationKey = ntfnKey;
        this.showLoader = false;
      } else {
        this.showLoader = false;
        this.notificationKey = "error-default";
        this.notificationMessage = response.data.message;
        this.notificationType = "error";
        this.showNotification = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
// 76 22
.isFilterOpen {width: 82%}
.bulk-edit-entity {
  word-break: initial;
}

.leftBlock {
  max-width: 94%;
  position: relative;
  overflow: hidden;
  margin-left: 16px;
  margin-right: 71px;
}
.rightBlock {
  width: 55px;
  padding: 0;
  margin-left: calc(100vw - 55px - var(--menuWidth));
  min-height: 100vh;
  border: 1px solid #979797;
  border-top: 1px solid #cccccc;
  position: fixed;
  z-index: 1;
  background-color: white;
}
.showFilterRightBlock {
  width: 292px;
  margin-left: calc(100vw - 292px - var(--menuWidth));
  position: fixed;
  z-index: 1;
}
.showFilterLeftBlock {
  max-width: 94%;
  margin-left: 16px;
  margin-right: 71px;
}
.template-header {
  @include mds-level-2-heading();
  margin: 16px 0px 5px 0px;
}
.vertical-line {
  box-sizing: border-box;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
  width: 1px;
  border-left: 1px dashed #cccccc;
}
.flex-buttons {
  margin-right: 16px;
  font-size: 16px;
}
.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
  margin-right: calc(292px - 55px);
}
.pagination-component {
  position: fixed;
  width: calc(100vw - 88px - var(--menuWidth));
  bottom: 0;
}
</style>
