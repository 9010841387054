<template>
<div id="dialogpopupClass">
    
<mds-dialog v-model="openDialogComponent" action-required width="500px">
    <div class="dialogContent">
       <mds-icon :name="iconDetails" class="dialogIcon" :class="iconColorClass"></mds-icon>
       <h4 class="messageToDisplay">{{dialogMessage}}</h4>
       <p v-if="dialogSubTextMessage"  class="dialogSubText">{{dialogSubTextMessage}}</p>
    </div>
     <template  v-slot:mds-dialog-actions-right class="dialogButtons">
        <mds-button-container >
            <button-component
            :onClick="onCancelClick"
            :buttonName="cancelButtonName"
            buttonVariation="secondary"
         ></button-component>
            <button-component
            v-if="displayConfirmButtons"
            :onClick="onConfirmClick"
            :buttonName="okButtonName"
            buttonVariation="primary"
            ></button-component>
        </mds-button-container>
    </template>
    
</mds-dialog>
</div>

</template>
<script>
import MdsDialog from '@mds/dialog';
import MdsIcon from '@mds/icon';
import ButtonComponent from './ButtonComponent.vue';
import { MdsButtonContainer } from "@mds/button";
export default {
    components:{
       MdsDialog,
       MdsIcon,
       ButtonComponent,
       MdsButtonContainer
    },
      computed: {
    iconColorClass() {
        if(this.iconColor == 'red'){
            return 'dialogIconColorRed'
        }else if(this.iconColor == 'blue'){
            return 'dialogIconColorBlue'
        }else{
             return 'dialogIconColorBlack';
        }
    }
  },
    data(){
        return{
            cancelButtonName:undefined,
            iconDetails:undefined,
            iconColor:undefined,
            dialogMessage:undefined,
            dialogSubTextMessage:undefined,
            okButtonName:undefined,
            displayConfirmButtons:false,
            openDialogComponent:false,
            resolvePromise: undefined,
            rejectPromise: undefined,
            onCloseGoBackTO:'',
            displayTime:true
        }
    },
    methods:{
         show(opts = {}) {
             this.iconDetails = opts.iconDetails
             this.dialogMessage=opts.dialogMessage
             this.okButtonName=opts.okButtonName
             this.cancelButtonName=opts.cancelButtonName
             this.displayConfirmButtons=opts.displayConfirmButtons
             this.displayTime=opts.displayTime
             this.dialogSubTextMessage= opts.dialogSubTextMessage;
             this.iconColor=opts.iconColor
             this.onCloseGoBackTO=opts.onCloseGoBackTO
             console.log( this.iconColor);
             this.openDialog()
              return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
         },
         openDialog(){
             this.openDialogComponent=true
         },
        onCancelClick(){
            this.openDialogComponent = false
            this.resolvePromise(false)
            if( this.onCloseGoBackTO){
                  this.$router.push({
                        name: this.onCloseGoBackTO,
                        params: { navigatedFrom: this.$route.path }    
                    });
            }
        },
        onConfirmClick(){
            this.openDialogComponent = false
            this.resolvePromise(true)
        }
    }
    
}
</script>
<style lang="scss" scoped>
  @import "../../assets/css/styles.scss";

  .dialogContent{
      width:100%;
      text-align:center;
  }
  .messageToDisplay{
    @include mds-level-4-heading();
    margin: 0;
    margin-top: $mds-space-2-and-a-half-x;
    color: #1E1E1E;
  }
  .dialogIcon{
      width:18%;
      height:20%;
      stroke-width:4px;
  }
  .dialogIconColorBlack{
       @include mds-icon-color($mds-color-neutral-37);
  }
  .dialogIconColorRed{
    @include mds-icon-color($mds-feedback-color-error);
  }
  .dialogIconColorBlue{
    @include mds-icon-color(#0077CF);
  }
  
   #dialogpopupClass::v-deep .mds-dialog__actions___VueMDS3Demo .mds-dialog__actions-right___VueMDS3Demo{
      margin: auto;
  }
  .dialogSubText{
      @include mds-unordered-list(medium);
      margin-top: $mds-space-2-and-a-half-x;
  }
//   .mds-dialog__actions___VueMDS3Demo .mds-dialog__actions-right___VueMDS3Demo
</style>