<template>
  <div id="list-group-table">
    <div
      style="
        height: auto; 
        max-height: 70vh; 
        overflow: auto
      "
    >
      <mds-data-table
        multiselection
        row-hover
        @mds-data-table-row-selection-change="rowClick"
        @mds-data-table-sort-change="sortClick($event)"
        @mds-data-table-header-select-all="headerSelected"
        :header-configs="headers"
        class="mds-data-table__row--selected___VueMDS3Demo"
        :row-data="
          filter
            ? rows.filter(
                (row) =>
                  row.entityname.toLowerCase().includes(filter.toLowerCase()) &&
                  row.entitytypeid == entityTypeId
              )
            : rows
        "
      >
        <template v-slot:body-cell="cellData">
          <!-- <div v-if=" cellData.rowIndex >= 0" :style="cellData.rowData.isChildEntityClicked && displayChildEntities ? 'background:rgba(0, 119, 207, 0.2);':''"> -->
             <template v-if="cellData.colIndex === 0">
               <span>{{
                cellData.rowData[cellData.headerConfig.fieldName]
              }}</span>
             </template>
          <template v-if="cellData.colIndex === 1">
            <div :style="style(cellData.rowData.paddingLeft)">
              <mds-button
                v-if="cellData.rowData.isClicked && displayChildEntities && checkToHideArrows(cellData.rowData.entitytypeid)"
                variation="icon-only"
                @click="
                  entityListCollaspeClicked(cellData.rowData, cellData);
                  cellData.rowData.isClicked = false;
                "
                icon="triangle-fill-down"
                type="button"
                size="small"
              ></mds-button>
              <mds-button
                v-if="!cellData.rowData.isClicked && displayChildEntities && checkToHideArrows(cellData.rowData.entitytypeid)"
                variation="icon-only"
                @click="
                  entityListClicked(cellData.rowData, cellData);
                  cellData.rowData.isClicked = true;
                "
                icon="triangle-fill-right"
                type="button"
                size="small"
              ></mds-button>
              <span>{{
                cellData.rowData[cellData.headerConfig.fieldName]
              }} 
              <mds-tag style="margin-left: 10px;"  v-if="cellData.rowData.isChildEntityClicked && displayChildEntities">
                Child Entity Selected
              </mds-tag>
            </span>
            </div>
          </template>
            <template v-if="cellData.colIndex === 2">
              <span>{{
                cellData.rowData[cellData.headerConfig.fieldName]
              }}</span>
             </template>
               <template v-if="cellData.colIndex === 3">
                 <span>{{
                cellData.rowData[cellData.headerConfig.fieldName]
              }}</span>
             </template>
               <template v-if="cellData.colIndex === 4">
                 <span>{{
                cellData.rowData[cellData.headerConfig.fieldName]
              }}</span>
             </template>
               <template v-if="cellData.colIndex === 5">
                 <span>{{
                cellData.rowData[cellData.headerConfig.fieldName]
              }}</span>
             </template>
          <template v-if="cellData.headerConfig.fieldName === 'action'">
            <mds-button-container>
              <mds-button
                variation="icon-only"
                :disabled="!(permissions.includes('bulk-edit-action-edit') || permissions.includes('*')) || rowSelectedItems.length != 0"
                @click="onEditButtonClicked(cellData.rowData)"
                @mouseover="onMouseOVerEditToolTip(cellData)"
                @mouseleave="onMouseLeaveEditToolTip(cellData)"
                :id="cellData.rowData.id + 'Edit'"
                icon="pencil"
                type="button"
              ></mds-button>
              <mds-button
                style="    margin-left: 8px;"
                variation="icon-only"
                @mouseover="onMouseOverAddToolTip(cellData)"
                @mouseleave="onMouseLeaveAddToolTip(cellData)"
                 :disabled="!(permissions.includes('bulk-edit-action-edit') || permissions.includes('*')) || 
                  rowSelectedItems.length != 0 || checkToDisableAddButton(cellData.rowData.entitytypeid)"
                @click="onAddButtonClick(cellData.rowData)"
                icon="plus"
                type="button"
                :id="cellData.rowData.id + 'AddChild'"
                permission="bulk-edit-action-add-child"
              ></mds-button>
              <mds-button
                style="    margin-left: 8px;"
                variation="icon-only"
                :disabled="!(permissions.includes('bulk-edit-action-edit') || permissions.includes('*')) || true || rowSelectedItems.length != 0"
                @click="onReportButtonClick(cellData.rowData, cellData)"
                @mouseover="onMouseOverReportToolTip(cellData)"
                @mouseleave="onMouseLeaveReportToolTip(cellData)"
                icon="document"
                type="button"
                :id="cellData.rowData.id + 'CreateReport'"
              ></mds-button>
            </mds-button-container>
          </template>
          <!-- </div> -->
        </template>
      </mds-data-table>
      
     <mds-empty-state
                   v-if="rows.length < 1"
                    class="no-results-message"
                    size="medium"
                    title="No results matched"
                    message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                ></mds-empty-state>
    </div>
    <add-entity-with-parent-details
      v-if="openAddEntityWithParentDetails"
      @clicked="closeAddEntityWithParentDetailsModal"
      @entityAdded="newEntityAdded"
    >
    </add-entity-with-parent-details>
    <dialog-component
      ref="confirmDialogue"
      :iconDetails="dialogIcon"
      :dialogMessage="dialogMessage"
    ></dialog-component>
    <div class="loaderCursor" v-if="showLoader">
      <loader-component loaderSize="large"></loader-component>
    </div>
  </div>
</template>
<script>
import { MdsDataTable } from "@mds/data-table";
// import MdsIcon from "@mds/icon";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import { mapGetters, mapMutations } from "vuex";
import AddEntityWithParentDetails from "../common_components/AddEntityWithParentDetails.vue";
import { insertNewEntity } from "../../services/add_investment_service";
import { dateFormatterMixin } from '../../mixins/date.js';
import DialogComponent from "../ui_component/DialogComponent.vue";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import Enums from "../../config/Enums.json";
import { MdsTag } from '@mds/tag'
// import MdsTooltip from '@mds/tooltip'
import MdsEmptyState from "@mds/empty-state";
export default {
  components: {
    MdsTag,
    DialogComponent,
    MdsDataTable,
    // MdsIcon,
    MdsButton,
    MdsButtonContainer,
    AddEntityWithParentDetails,
    LoaderComponent,
    // MdsTooltip,
    MdsEmptyState
  },
  props: {
    rowData: {
      required: true,
    },
    filter: {
      required: false,
    },
    offset: {
      required: false,
      default: 0,
    },
    entityTypeId: {
      default: 1,
    },
    displayChildEntities: {
      default: true,
    },
  },

  mixins: [ dateFormatterMixin ],

  watch: {
   async displayChildEntities() {
     await this.uncheckAllRows(false);
     await this.removeChildEntites()
    },
    rowData(val) {
      this.rows = val;
      this.dupRow=JSON.parse(JSON.stringify(this.rows))
    },
    rows(val) {
      this.dupRow=JSON.parse(JSON.stringify(val))
    },
    // rows: {
    //   immediate: true,
    //   deep: true,
    // },
  },
  data() {
    return {
      enums:Enums,
      toggleToolTip: false,
      showLoader: false,
      dialogIcon: "",
      dialogMessage: "",
      notificationMessage: "",
      openAddEntityWithParentDetails: false,
      selected: [],
      rowSelectedItems: [],
      sortOrder: 1,
      headers: [
        {
          fieldName: "entityid",
          text: "",
          width: "1",
          sortable: false,
        },
        {
          fieldName: "entityname",
          text: "Entity Name",
          width: "60%",
          sortable: true,
          sorted: 1,
        },
        {
          fieldName: "entitytypedescription",
          text: "Type",
          width: "20%",
          align: "left",
          sortable: false,
        },
        {
          fieldName: "status",
          text: "Status",
          width: "20%",
          align: "left",
          sortable: false,
        },
        {
          fieldName: "createddate",
          text: "Last Updated (UTC)",
          width: "20%",
          align: "left",
          sortable: false,
        },
        {
          fieldName: "action",
          text: "Actions",
          width: "10%",
          align: "right",
          sortable: false,
        },
      ],
      rows: [],
      dupRow:[]
    };
  },
  mounted(){
      this.rows = this.rowData
      this.dupRow=JSON.parse(JSON.stringify(this.rows))
     
  },
  computed: {
    ...mapGetters("bulkEdit", ["getCheckedEntites", "getSelectedFilterEntity"]),
     ...mapGetters("entity",["getReferenceEntityTypesDropdown"]),
    permissions () {
      return this.$store.state.auth.permissions;
    }
  },
  methods: {
    ...mapMutations("entity", ["setSelectedEntity"]),
    ...mapMutations("bulkEdit", [
      "setCheckedEntites",
      "removeCheckedEntites",
      "emptyCheckedEntites",
      "setPageSize",
    ]),
    checkToDisableAddButton(entitytypeid){
       for(let i=0;i<this.getReferenceEntityTypesDropdown.length;i++){
        if(this.getReferenceEntityTypesDropdown[i].entitytypeid == entitytypeid){
          if(this.getReferenceEntityTypesDropdown[i].refrencetype){
            return true
          }else{
            if(this.getReferenceEntityTypesDropdown[i].childentityid == 0){
              return true
            }else{
              return false
            }
          }
        }
      }
    },
    checkToHideArrows(entitytypeid){
       for(let i=0;i<this.getReferenceEntityTypesDropdown.length;i++){
        if(this.getReferenceEntityTypesDropdown[i].entitytypeid == entitytypeid){
            if(this.getReferenceEntityTypesDropdown[i].childentityid == 0){
              return false
            }else{
              return true
            }
          
        }
      }
    },
    onMouseOVerEditToolTip(cell){
      this.dupRow=[]
      this.dupRow=JSON.parse(JSON.stringify(this.rows))
      this.dupRow[cell.rowIndex].showEditToolTip=true
      this.$emit("update:rowData",this.dupRow)
    },
    onMouseLeaveEditToolTip(cell){
       this.dupRow=[]
      this.dupRow=JSON.parse(JSON.stringify(this.rows))
      this.dupRow[cell.rowIndex].showEditToolTip=false
      this.$emit("update:rowData",this.dupRow)
    },
    onMouseOverAddToolTip(cell){
      this.dupRow=[]
      this.dupRow=JSON.parse(JSON.stringify(this.rows))
      this.dupRow[cell.rowIndex].showAddToolTip=true
      this.$emit("update:rowData",this.dupRow)
      // 
      // 
    },
    onMouseLeaveAddToolTip(cell){
       this.dupRow=[]
      this.dupRow=JSON.parse(JSON.stringify(this.rows))
      this.dupRow[cell.rowIndex].showAddToolTip=false
      this.$emit("update:rowData",this.dupRow)
    },
    onMouseOverReportToolTip(cell){
       this.dupRow=[]
      this.dupRow=JSON.parse(JSON.stringify(this.rows))
      this.dupRow[cell.rowIndex].showReportToolTip=true
      this.$emit("update:rowData",this.dupRow)
      // 
      // showReportToolTip
    },
    onMouseLeaveReportToolTip(cell){
       this.dupRow=[]
      this.dupRow=JSON.parse(JSON.stringify(this.rows))
      this.dupRow[cell.rowIndex].showReportToolTip=false
      this.$emit("update:rowData",this.dupRow)
    },
    // <---*** code in the comment section is used to enable the buttons on click of the entity not removing --
    // this code because can be used later if required
    /**  isButtonDisabled(rowSelected){
      if(this.rowSelectedItems.length == 0){
        return false
      }else{
       return true
      }

      
      // else if(!(!(!rowSelected && this.rowSelectedItems.length >=1 && this.rowSelectedItems.length != 0) 
      // && this.rowSelectedItems.length==1)){
      //   return true
      // }
    },
    */
    async uncheckAllRows(arg) {
      let selectedCheckboxes=''
      if(arg){
        selectedCheckboxes = document.querySelectorAll(
          "input[type=checkbox]:not(:checked)"
        );
      }else{
        selectedCheckboxes = document.querySelectorAll(
          "input[type=checkbox]:checked"
        );
      }
      this.rows.forEach((item) => {
        selectedCheckboxes.forEach((elem) => {
          if (
            elem.defaultValue != "childEntity" &&
            elem.defaultValue != "Approved" &&
            elem.defaultValue != "Draft"
          ) {
            if (elem.defaultValue == item.entityid && !elem.checked) {
              elem.click();
            } else if (elem.defaultValue == item.entityid && elem.checked) {
              elem.click();
            }
          }
        });
      });
    },
    async headerSelected(arg) {
      await this.removeChildEntites();
      let selectedCheckboxes=''
      if(arg){
        selectedCheckboxes = document.querySelectorAll(
          "input[type=checkbox]:not(:checked)"
        );
      }else{
        selectedCheckboxes = document.querySelectorAll(
          "input[type=checkbox]:checked"
        );
      }
      this.rows.forEach((item) => {
        selectedCheckboxes.forEach((elem) => {
          if (
            elem.defaultValue != "childEntity" &&
            elem.defaultValue != "Approved" &&
            elem.defaultValue != "Draft"
          ) {
            if (elem.defaultValue == item.entityid && !elem.checked) {
              elem.click();
            } else if (elem.defaultValue == item.entityid && elem.checked) {
              elem.click();
            }
          }
        });
      });
    },
    removeChildEntites() {
      var i = this.rows.length;
      let checkedItemsList=[]
      while (i--) {
        if (this.rows[i].entitytypeid != this.getSelectedFilterEntity) {
          for (let j = 0; j < this.getCheckedEntites.length; j++) {
              if (this.getCheckedEntites[j].id == this.rows[i].entityid) {
                this.removeCheckedEntites(j);
              }
            }
          this.rows.splice(i, 1);
        } else {
          this.rows[i].isClicked = false;
        }
      }
       for (let k = 0; k < this.getCheckedEntites.length; k++) {
        checkedItemsList.push(this.getCheckedEntites[k].id);
      }
      this.rowSelectedItems = checkedItemsList;
      this.$emit("updateEntities", checkedItemsList);
    },
    sortClick(arg) {
      this.$emit("columnSorted", arg);
    },
    onSort(arg) {
      let order = arg.sortOrder;
      // order=-order
      this.rows = this.rows.sort((a, b) =>
        a[`${arg.sortField}`] >= b[`${arg.sortField}`] ? -order : order
      );
      var entityTypeIds = [];
      for (let i = 0; i < this.rows.length; i++) {
        entityTypeIds.push(this.rows[i].entitytypeid);
      }
      entityTypeIds = [...new Set(entityTypeIds)].sort();
      for (let j = 0; j < entityTypeIds.length; j++) {
        if (entityTypeIds[j] != this.getSelectedFilterEntity) {
          var getEntityTypeObjects = this.rows.filter(
            (row) => row.entitytypeid == entityTypeIds[j]
          );
          getEntityTypeObjects = getEntityTypeObjects.sort((a, b) =>
            a[`${arg.sortField}`] <= b[`${arg.sortField}`] ? -order : order
          );
          for (let k = 0; k < getEntityTypeObjects.length; k++) {
            for (let m = 0; m < this.rows.length; m++) {
              if (getEntityTypeObjects[k].id == this.rows[m].id) {
                this.rows.splice(m, 1);
              }
            }
            for (let l = 0; l < this.rows.length; l++) {
              if (getEntityTypeObjects[k].parententityid == this.rows[l].id) {
                this.rows.splice(l + 1, 0, getEntityTypeObjects[k]);
              }
            }
          }
        }
      }
    },
    rowClick(e) {
      var checkedItems = [];
      if (e.checked) {
        var selectedRow = this.rowData.filter((row) => row.id == e.id);
        var selectedObject = {
          id: selectedRow[0].id,
          entitytypeid: selectedRow[0].entitytypeid,
          parententityid:selectedRow[0].parententityid,
          parentEntityDetails:[]
        };
        if (
          this.getCheckedEntites.length &&
          this.getCheckedEntites.length >= 1
        ) {
          if (
            this.getCheckedEntites[0].entitytypeid !==
            selectedRow[0].entitytypeid
          ) {
            this.reset(e);
            this.emptyCheckedEntites([]);
          }
        }
        if (this.getCheckedEntites.length == 0) {
          this.setCheckedEntites(selectedObject);
        } else {
          for (let i = 0; i < this.getCheckedEntites.length; i++) {
            if (this.getCheckedEntites[i].id == e.id) {
              break;
            } else {
              if (i == this.getCheckedEntites.length - 1) {
                this.setCheckedEntites(selectedObject);
              }
            }
          }
        }
      } else { 
        for (let i = 0; i < this.getCheckedEntites.length; i++) {
          if (this.getCheckedEntites[i].id == e.id) {
            this.removeCheckedEntites(i);
          }
        }
      }
      for (let i = 0; i < this.getCheckedEntites.length; i++) {
        checkedItems.push(this.getCheckedEntites[i].id);
      }
      this.rowSelectedItems = checkedItems;
      this.$emit("updateEntities", checkedItems);
    },
    event(e) {
      if (e.checked) {
        this.selected.push(e.id);
        if (this.getCheckedEntites.length == 0) {
          this.setCheckedEntites(e.id);
        } else {
          for (let i = 0; i < this.getCheckedEntites.length; i++) {
            if (this.getCheckedEntites[i] == e.id) {
              break;
            } else {
              if (i == this.getCheckedEntites.length - 1) {
                this.setCheckedEntites(e.id);
              }
            }
          }
        }
        if (this.selected.length && this.selected.length > 1) {
          let oldType = this.rowData.filter(
            (row) => row.id === this.selected[0]
          )[0].entitytypeid;
          let newType = this.rowData.filter(
            (row) => row.id === this.selected[this.selected.length - 1]
          )[0].entitytypeid;
          oldType !== newType ? this.reset() : null;
        }
        if (
          this.getCheckedEntites.length &&
          this.getCheckedEntites.length > 1
        ) {
          let oldType = this.rowData.filter(
            (row) => row.id === this.getCheckedEntites[0]
          )[0].entitytypeid;
          let newType = this.rowData.filter(
            (row) =>
              row.id ===
              this.getCheckedEntites[this.getCheckedEntites.length - 1]
          )[0].entitytypeid;
          oldType !== newType ? this.reset() : null;
        }
      } else {
        this.selected = this.selected.filter((item) => item !== e.id);
        for (let i = 0; i < this.getCheckedEntites.length; i++) {
          if (this.getCheckedEntites[i] == e.id) {
            this.removeCheckedEntites(i);
          }
        }
      }

      this.$emit("updateEntities", this.selected);
    },
    reset(currentlySelectedNode) {
      let selectedCheckboxes = document.querySelectorAll(
        "input[type=checkbox]:checked"
      );
      // clicking all the checked checkboxes to uncheck them
      // selectedCheckboxes.forEach((elem) => elem.click());
      // selectedCheckboxes.forEach((elem) => {
      //   // if(item.entityid){}
      //   console.log(elem.defaultValue);
      //   if (
      //     elem.defaultValue != "childEntity" &&
      //     elem.defaultValue != "Approved" &&
      //     elem.defaultValue != "Draft"
      //   ) {
      //     elem.click();
      //   }
      // });
      this.rows.forEach((item) => {
        selectedCheckboxes.forEach((elem) => {
          if (
            elem.defaultValue != "childEntity" &&
            elem.defaultValue != "Approved" &&
            elem.defaultValue != "Draft"
          ) {
            if(elem.value != currentlySelectedNode.id.toString()){
              if (elem.defaultValue == item.entityid && !elem.checked) {
              elem.click();
            }else if (elem.defaultValue == item.entityid && elem.checked) {
              elem.click();
            }
            }
          }
        });
      });
    },
    style(paddingSize) {
      return {
        "padding-left": `${paddingSize}px`,
      };
      // if (entityTypeId != 1) {
      //   var paddingSize = 16 * (entityTypeId - 1 - this.offset);
      //   return {
      //     "padding-left": `${paddingSize}px`,
      //   };
      // } else {
      //   return "";
      // }
    },
    entityListClicked(row, cell) { 
      this.$emit("entityClicked", row, cell);
    },
    entityListCollaspeClicked(row,cell) {
      this.$emit("entityCollasped", row.id,cell);
    },
    onEditButtonClicked(rowData) {
      this.setPageSize(10);
      this.$router.push(`/data-management/add-investment?entityid=${rowData.entityid}&isEditable=${false}`);
    },
    onAddButtonClick(rowData) {
      this.setSelectedEntity(rowData);
      this.openAddEntityWithParentDetails = true;
    },
    onReportButtonClick(rowData, cellData) {
      console.log(rowData);
      console.log(cellData);
    },
    closeAddEntityWithParentDetailsModal(value) {
      this.openAddEntityWithParentDetails = value;
    },
    async newEntityAdded(value) {
      this.showLoader = true;
      this.openAddEntityWithParentDetails = false;
      this.$emit("updateEntities", value);
      var response = await insertNewEntity(value);
      let iconDetailsForNotification;
      let iconColor;
      if (!response.isError) {
        if (response.data.message.trim().length == 0) {
          // await this.getEntityDetails(addEntityResponseData);
          // var createdEntityNode= ''

          this.notificationMessage = "Entity successfully created";
          iconDetailsForNotification = "check";
          iconColor = "blue";
        } else {
          this.notificationMessage = response.data.message;
          iconDetailsForNotification = "alert";
          iconColor = "red";
        }
      } else {
        this.notificationMessage = "Something went wrong";
        iconDetailsForNotification = "alert";
        iconColor = "red";
      }

      this.showLoader = false;
      await this.$refs.confirmDialogue.show({
        dialogMessage: this.notificationMessage,
        cancelButtonName: "Close",
        iconDetails: iconDetailsForNotification,
        displayConfirmButtons: false,
        iconColor: iconColor,
        dialogSubTextMessage: this.getUTCFormatForCurrentDate()
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.dataPointSubLabel {
  @include mds-eyebrow-heading();
}
.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}
</style>
